import Header from "../Header/Header"
import './ExamReport.css'
import UserInfo from '../UserInfo/UserInfo.jsx'
import axios from 'axios';
import { useState , useEffect , useRef } from 'react';
import { PieChart, Pie, Legend, Tooltip , Cell , BarChart, Bar, XAxis, YAxis, CartesianGrid,ResponsiveContainer , LabelList} from 'recharts';
import { MdOutlineDataSaverOn } from "react-icons/md";
import Modal from 'react-modal'
import { IoMdClose } from "react-icons/io";
import {FaAngleRight,FaAngleLeft} from "react-icons/fa";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";


export default function ExamReport(){
    const back_ip_url = process.env.REACT_APP_SERVER_URL
    const [courseData,setCourseData] = useState()
    const [studentCourseData,setStudentCourseData] = useState();
    const [selectedStu,setSelectedStu] = useState([]);
    const [selectedStuName,setSelectedStuName] = useState();
    const [examList, setExamList] = useState([]);
    const [examQuestions, setExamQuestions] = useState([]);
    const [examStudents, setExamStudents] = useState([]);
    const [workbook,setWorkbook] = useState([])
    const [workbookStudent,setWorkbookStudent] = useState([])
    const [studentData,setStudentData] = useState()

    const [incorrectReportData,setIncorrectReportData]= useState([])
    const [totalReportData,setTotalReportData]= useState([])

    

    const [count,setCount] = useState(0);
    const userInfo = UserInfo().userInfo;
    const isLogin = UserInfo().isLogin;

    const [selectedRow, setSelectedRow] = useState();
    const [selectedRow2, setSelectedRow2] = useState();
    const [selectedRow3, setSelectedRow3] = useState();

    //db 가져오기
    const fetchStudentData = () =>{
        axios({
            method: "GET",
              url: `${back_ip_url}/fetchStudentData`,
              params: userInfo,
              withCredentials : true,
          })
          .then(response => {
              setStudentData(response.data);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
        }
    
    const fetchCourseData = () =>{
        axios({
            method: "GET",
                url: `${back_ip_url}/fetchCourseData`,
                params: userInfo,
                withCredentials : true,
            })
            .then(response => {
                setCourseData(response.data);
            })
            .catch(error => {
                console.error('Error fetching tree data:', error);
            });
        }
    
    const fetchStudentCourseData = () =>{
        axios({
            method: "GET",
                url: `${back_ip_url}/fetchStudentCourseData`,
                params: userInfo,
                withCredentials : true,
            })
            .then(response => {
                setStudentCourseData(response.data);
            })
            .catch(error => {
                console.error('Error fetching tree data:', error);
            });
    }
    const fetchExamData = () =>{
        axios({
            method: "POST",
                url: `${back_ip_url}/fetchExamData`,
                data:{
                    userId : userInfo.id
                },
                withCredentials : true,
            })
            .then(response => {
            const { examList, examQuestions, examStudents } = response.data;
            setExamList(examList);
            setExamQuestions(examQuestions);
            setExamStudents(examStudents);
            })
            .catch(error => {
                console.error('Error fetching tree data:', error);
            });
    }

    useEffect(() => {
    if (isLogin) {  // 로그인 상태인지 확인
        const timer = setTimeout(() => {
            // 서버로부터 학생, 수강반 데이터 가져오기
            fetchStudentData();
            fetchCourseData();
            fetchStudentCourseData();
            fetchExamData();
        }, 100); // 0.1초 뒤에 실행

        return () => clearTimeout(timer);
    }
    }, [userInfo,count]);

    const handleCheckboxChange = (index, isChecked) => {
        if (isChecked) {
            if (selectedStu.length >= 1) {
                alert("두 개 이상의 체크박스를 선택할 수 없습니다.");
                return;
            }
            setSelectedStu(prevIds => [...prevIds, index]);
            setSelectedStuName(studentData.find(student => student.id === selectedStu))
        } else {
            setSelectedStu(prevIds => prevIds.filter(id => id !== index));
        }
    };


    const handleRowClick = (index) => {
        setSelectedRow(index);
    }        
    const handleRowClick2 = (index) => {
        setSelectedRow2(index);
    }        
    const handleRowClick3 = (index) => {
        setSelectedRow3(index);
    }

    //날짜
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    };

    //학교 학년
    const school_grade = [
        { id: 15, name: "=============" },
        { id: 1, name: "초등학교 1학년" },
        { id: 2, name: "초등학교 2학년" },
        { id: 3, name: "초등학교 3학년" },
        { id: 4, name: "초등학교 4학년" },
        { id: 5, name: "초등학교 5학년" },
        { id: 6, name: "초등학교 6학년" },
        { id: 13, name: "=============" },
        { id: 7, name: "중학교 1학년" },
        { id: 8, name: "중학교 2학년" },
        { id: 9, name: "중학교 3학년" },
        { id: 14, name: "=============" },
        { id: 10, name: "고등학교 1학년" },
        { id: 11, name: "고등학교 2학년" },
        { id: 12, name: "고등학교 3학년" }
    ];
        //학년 표시
    const getGradeName = (gradeId) => {
        const grade = school_grade.find(g => g.id == gradeId);
        return grade ? grade.name : '학년 정보 없음';
    };
    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; // dateString이 null이나 undefined인 경우 빈 문자열 반환
        }
    
        const date = new Date(dateString); // Date 객체로 변환
    
        // UTC 시간을 9시간 더해서 한국 시간대로 변환
        const kstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
    
        const year = kstDate.getFullYear();
        const month = String(kstDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
        const day = String(kstDate.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`; // 'YYYY-MM-DD' 형식으로 반환
    };
    const [currentDate, setCurrentDate] = useState('');
    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD' 형식으로 포맷팅
                const sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(today.getMonth() - 6);
        
        const formattedToday = today.toISOString().split('T')[0];
        const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split('T')[0];
        setCurrentDate(formattedDate);
        setStartDate(formattedSixMonthsAgo)
        setEndDate(formattedToday)
    }, []);
    const [indexToExclude,setIndexToExclude] = useState([])
    const filteredExam = examStudents.filter(item => selectedStu.includes(item.student_id))
                        .filter(item => item.score !== null);  // score가 null이 아닌 항목만 포함;
    const mergedData = filteredExam.map(studentProblem => {
        const examInfo = examList.find(exam => exam.exam_id === studentProblem.exam_id);
        const studentInfo = studentData.find(student => student.student_id === studentProblem.student_id);
        return {
            ...studentProblem,
            ...examInfo,
            student: studentInfo
        };
    }).filter(data=>{
        return data.date >= startDate && data.date <= endDate;
    })



    const filteredWorkbook = workbookStudent.filter(item=> selectedStu.includes(item.student_id))
                        .filter(item => item.score !== null);  // score가 null이 아닌 항목만 포함;
    const mergedBookData = filteredWorkbook.map(studentProblem =>{
        const bookInfo = workbook.find(workbook => workbook.id === studentProblem.book_id)
        const studentInfo = studentData.find(student => student.student_id === studentProblem.student_id);
        const examInfo = examList.find(exam => exam.exam_id === studentProblem.exam_id)
        return {
            ...studentProblem,
            ...bookInfo,
            ...examInfo,
            student : studentInfo
        }
    }).filter(data=>{
        return data.date >= startDate && data.date <= endDate;
    })
    function excludeIndex(indexToExclude) {
        return mergedData.filter((_, index) => !indexToExclude.includes(index));
    }
    const newMergeData = excludeIndex(indexToExclude);
    ////로그 확인
    const logcheck = () =>{
        // console.log("mergedData",mergedData)
        // console.log("mergedBookData",mergedBookData)
        // console.log("totalReportData",totalReportData)
        // console.log("incorrectReportData",incorrectReportData)
        // console.log("totalSectionData",totalSectionData)
        // console.log("incorrectSectionData",incorrectSectionData)
        // console.log("indexToExclude",indexToExclude)
        // console.log("newMergeData",newMergeData)
        console.log("studentData",studentData)
        console.log("SelectedStu",selectedStu)
        console.log(studentData !== undefined && selectedStu.length > 0 ? ('true'):('false'))
        console.log(studentData !== undefined )
        console.log(selectedStu.length > 0 )
    //     console.log("totalSectionData['1단원']",totalSectionData['1단원'])
    //     console.log("incorrectSectionData['1단원']",incorrectSectionData['1단원'])
    //     console.log("calculateDifficultyPercentage(totalSectionData['1단원'])",calculateDifficultyPercentage(totalSectionData['1단원']))
    //     console.log("calculateDifficultyPercentage(incorrectSectionData['1단원'])",calculateDifficultyPercentage(incorrectSectionData['1단원']))

    //     console.log(calculateDifficultyPercentage2(totalSectionData['1단원'], incorrectSectionData['1단원']))

    //     console.log(sumArrayLengths(totalSectionData['1단원']))
    //     console.log(makeCompareData(totalSectionData['1단원'], incorrectSectionData['1단원']))
    }
    ////성적표 생성
    const [totalSectionData,setTotalSectionData] = useState()
    const [incorrectSectionData,setIncorrectSectionData] = useState()

    const makeReport = () =>{
        const result = window.confirm("화면에 보이는 시험지로 성적표를 만드시겠습니까?");
            if (result) {
                    axios({
                        method: "POST",
                        url: `${back_ip_url}/makeReport`,
                        data : {
                        student_id : selectedStu,
                        exam_data: newMergeData,
                        book_data: mergedBookData,
                        userId : userInfo.id
                    },
                        withCredentials : true,
                    })
                    .then(response => {
                    if (response.status === 200) {
                        setCount(count+1)
                        setModalIsOpen(true)
                        setTotalReportData(response.data.totalResults)
                        setIncorrectReportData(response.data.incorrectResults)
                        const sections = Array.from(new Set(response.data.totalResults.map(item => item.top_section)));
                        const difficulty = Array.from(new Set(response.data.totalResults.map(item => item.difficulty_level)));

                        let sectionObj = {}
                        for(const section of sections){
                            sectionObj[section] = {}
                            for(const difficult of difficulty){
                                sectionObj[section][difficult] = response.data.totalResults.filter(item => item.top_section === section && item.difficulty_level === difficult);
                            }
                        }    
                        setTotalSectionData(sectionObj)  
                        
                        let incorrectSectionObj = {}
                        for(const section of sections){
                            incorrectSectionObj[section] = {}
                            for(const difficult of difficulty){
                                incorrectSectionObj[section][difficult] = response.data.incorrectResults.filter(item => item.top_section === section && item.difficulty_level === difficult);
                            }
                        }    
                        setIncorrectSectionData(incorrectSectionObj)       
                    }
                    })
                    .catch(error => {
                        console.error('Error delete exam:', error);
                    });
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }

    }

    
    ////성적표 표시
    const [modalIsOpen,setModalIsOpen] = useState(false)
    const chart_colors = ['#0089D1'
                        , '#F1E737'
                        , '#DB3954'
                        , '#00C49F'
                        , '#F5A683']
    const chart_colors2 = [  '#005B9A', '#66A2D3'
                           , '#D4C42F', '#F6F066'
                           , '#B82C3C', '#E86B7C'
                           , '#009A7D', '#33D8B6'
                           , '#D87D5C', '#F7B99E']
    const closeModal = () =>{
        setModalIsOpen(false)
    }
    //시험지 제외 버튼
    const excludeExam = (index) =>{
        setIndexToExclude((prevIndexes) => {
            // 인덱스가 이미 배열에 있는지 확인
            if (prevIndexes.includes(index)) {
                // 이미 존재하면, 해당 인덱스를 제거한 배열 반환
                return prevIndexes.filter((i) => i !== index);
            } else {
                // 존재하지 않으면, 인덱스를 배열에 추가
                return [...prevIndexes, index];
            }
        });
    }

    const calculateDifficultyPercentage = (sectionData) => {
        return Object.entries(sectionData).map(([difficulty, questions]) => ({
            name: `난이도 ${difficulty}`,
            value: questions.length 
        }));
    };

    const calculateDifficultyPercentage2 = (sectionData,incorrect_sectionData) => {
        const totalData = Object.entries(sectionData).map(([difficulty, questions]) => ({
            name: `난이도 ${difficulty}`,
            value: questions.length 
        }));
        const incorrectData = Object.entries(incorrect_sectionData).map(([difficulty, questions]) => ({
            name: `난이도 ${difficulty}`,
            value: questions.length 
        }));
        function subtractValues(array1, array2) {
            // 두 번째 배열을 객체 형태로 변환 (name을 키로 사용)
            const array2Map = new Map(array2.map(item => [item.name, item.value]));
        
            // 첫 번째 배열의 각 항목에 대해 value를 뺀 결과를 반환
            return array1.map(item => ({
                name: item.name,
                value: item.value - (array2Map.get(item.name) || 0) // 두 번째 배열에 없는 경우 0으로 처리
            }));
        }
        const correctData = subtractValues(totalData, incorrectData);

        const mergeArrays = (arr1, arr2) => {
            const merged = [];
            const length = Math.max(arr1.length, arr2.length);
            
            for (let i = 0; i < length; i++) {
                if (i < arr1.length) {
                    merged.push(arr1[i]);
                }
                if (i < arr2.length) {
                    merged.push(arr2[i]);
                }
            }
            
            return merged;
        };

        const mergedData = mergeArrays(correctData,incorrectData)
        return mergedData
    };
    const makeCompareData = (sectionData,incorrect_sectionData) =>{
        const totalData = Object.entries(sectionData).map(([difficulty, questions]) => ({
            name: `난이도 ${difficulty}`,
            value: questions.length 
        }));
        const incorrectData = Object.entries(incorrect_sectionData).map(([difficulty, questions]) => ({
            name: `난이도 ${difficulty}`,
            value: questions.length 
        }));
        function subtractValues(array1, array2) {
            // 두 번째 배열을 객체 형태로 변환 (name을 키로 사용)
            const array2Map = new Map(array2.map(item => [item.name, item.value]));
        
            // 첫 번째 배열의 각 항목에 대해 value를 뺀 결과를 반환
            return array1.map(item => ({
                name: item.name,
                value: item.value - (array2Map.get(item.name) || 0) // 두 번째 배열에 없는 경우 0으로 처리
            }));
        }
        const correctData = subtractValues(totalData, incorrectData);

        const mergedArray = correctData.map(item1 => {
            const item2 = incorrectData.find(item => item.name === item1.name);
            return {
                "name": item1.name,
                "정답 문항수": item1.value,
                "오답 문항수": item2.value
            };
        });

        return mergedArray
    }

    const legendPayload = [
        { value: '난이도 1', color: '#0089D1' },  // 예시 항목
        { value: '난이도 2', color: '#F1E737' },  // 예시 항목
        { value: '난이도 3', color: '#DB3954' },  // 예시 항목
        { value: '난이도 4', color: '#00C49F' },  // 예시 항목
        { value: '난이도 5', color: '#F5A683' },  // 예시 항목
        
    ];
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
    };
    //대단원 전체 문제수를 구해주는 함수
    function sumArrayLengths(data) {
        return Object.values(data).reduce((sum, array) => sum + array.length, 0);
    }

    const onClickDownloadButton = () => {
        const target = document.getElementById("download");
        if (!target) {
            return alert("사진 저장에 실패했습니다.");
        }
        html2canvas(target).then((canvas) => {
            const link = document.createElement("a");
            document.body.appendChild(link);
            link.href = canvas.toDataURL("image/png");
            link.download = "report.png"; // 다운로드 이미지 파일 이름
            link.click();
            document.body.removeChild(link);
        });
    };

    const sectionsPerPage = 3; // 페이지당 표시할 섹션 수

    // 페이지를 관리하는 state
    const [currentPage, setCurrentPage] = useState(0);

    // 섹션을 페이지 단위로 나눔
    const sectionGroups = totalSectionData && Object.keys(totalSectionData).length > 0
    ? Object.keys(totalSectionData).reduce((acc, key, index) => {
        const groupIndex = Math.floor(index / sectionsPerPage);
        if (!acc[groupIndex]) acc[groupIndex] = [];
        acc[groupIndex].push(key);
        return acc;
    }, [])
    : [];
    const totalPages = sectionGroups.length;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const CustomLabel = (props) => {
        const { x, y, value, width, height } = props;
      
        // 값이 0일 경우 라벨을 표시하지 않음
        if (value === 0) return null;
      
        // x, y 좌표를 그대로 사용하여 중앙에 배치
        return (
          <text 
            x={x + width / 2} 
            y={y + height / 2} 
            fill="#fff" 
            textAnchor="middle" 
            dominantBaseline="middle"
          >
            {value}
          </text>
        );
      };
    const printRef = useRef();

    const handlePrint = () => {
        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };
    
    return(
        <div>
            <Modal className='Modal_ExamReport' isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className="Modal_head">
                    <h2>성적표</h2>
                    <button onClick={onClickDownloadButton} className="buttonA btnColorA" style={{width : "300px"}}>현재 페이지 이미지로 저장</button>
                    <ReactToPrint
                        trigger={() => <button className="buttonA btnColorA" style={{width : "300px"}}>현재 페이지 인쇄</button>}
                        content={() => printRef.current}
                        pageStyle="@page { size: A4; margin: 0; }"
                    />
                    <button className="xMark" onClick={() => closeModal()}><IoMdClose /></button>
                </div>
                <div className="pagination"
                    style={{margin: "10px"}}>
                        <button 
                            onClick={() => handlePageChange(currentPage - 1)} 
                            disabled={currentPage === 0}
                            style={{top:'5px'}}
                        >
                            <FaAngleLeft />
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i + 1}
                                onClick={() => handlePageChange(i)}
                                className={currentPage === i  ? 'active' : ''}
                                style={{
                                    marginTop: '-4px'  // 버튼을 위로 3px 이동
                                }}
                            >
                                {i + 1}
                            </button>
                        ))}
                        <button 
                            onClick={() => handlePageChange(currentPage + 1)} 
                            disabled={currentPage === totalPages - 1}
                        >
                            <FaAngleRight />
                        </button>
                </div>
                <div className="graph_div print-area" id="download" ref={printRef}>                    
                    <p style={{marginTop:'5px', fontSize:'20px', fontWeight:'600'}}>Indivisual Report | {endDate} | {studentData !== undefined && selectedStu.length > 0 ? (studentData.filter(student => student.student_id == selectedStu)[0].student_name):('wait')}</p>
                    {totalSectionData && sectionGroups[currentPage].map(section => (
                        <div key={section}>
                            <h4>{section} <br/>[전체 : {sumArrayLengths(totalSectionData[section])} 문항]</h4>
                            <div className="chart">
                                <PieChart width={300} height={260}>
                                    <Pie
                                        data={calculateDifficultyPercentage(totalSectionData[section])}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={50}
                                        outerRadius={70}
                                        paddingAngle={5}
                                        fill="#8884d8"
                                        label
                                    >
                                        {calculateDifficultyPercentage(totalSectionData[section]).map((entry, index) => (
                                                <Cell
                                                key={`cell-${index}`}
                                                fill={chart_colors[index % chart_colors.length]} // 색상 배열 순환
                                            />
                                        ))}
                                    </Pie>
                                    <Legend />
                                </PieChart>
                                    <BarChart
                                    width={450}
                                    height={260}
                                    data={makeCompareData(totalSectionData[section], incorrectSectionData[section])}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5, 
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="정답 문항수" stackId="a" fill="#4CAF50">
                                        <LabelList dataKey="정답 문항수" content={<CustomLabel />} position="inside"/>
                                    </Bar>
                                    <Bar dataKey="오답 문항수" stackId="a" fill="#F44336">
                                        <LabelList dataKey="오답 문항수" content={<CustomLabel />} position="inside"/>
                                    </Bar>
                                    </BarChart>
                            </div>
                        </div>
                    ))}
                    <div className="report_comment" style={{marginTop:"10px"}}>
                        <h3 >선생님 코맨트</h3>
                        <textarea placeholder="코멘트를 입력하세요" rows="3" style={{width: "100%" ,resize: "none", fontSize: "18px" , marginTop:"10px"}}></textarea>
                    </div>
                </div>
            </Modal>
            <Header></Header>
            <div className="main">
                <div className="StudentList">
                    <div className="topBtn">
                        <button className="buttonA btnColorA"  style={{ width: "100%", margin : "5px" ,fontSize : "18px"}}onClick={makeReport} disabled={!selectedStu.length == 1}><MdOutlineDataSaverOn className="iconA"/> 성적표 생성</button>
                    </div>
                    <div className="listHeader_Stu block divtable" id="listHeader_Stu">
                        <table>
                            <thead>
                            <tr>
                                <th>이름</th>
                                <th>학년</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {studentData ? (studentData.map((student, index) => (
                                    <tr key={student.student_id} style={{ backgroundColor: 'white' }} onClick={() => handleRowClick(index)}>
                                        <td>{student.student_name}</td>
                                        <td>{getGradeName(student.grade)}</td>
                                        <td> {/* 선택 버튼 */}
                                        <input 
                                            type="checkbox" 
                                            checked={selectedStu.includes(student.student_id)} 
                                            onChange={(e) => handleCheckboxChange(student.student_id, e.target.checked)} 
                                        />
                                        </td>
                                    </tr>
                                ))) : ('학생 정보 불러오는 중...')}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {/* <button onClick={logcheck}>logcheck</button> */}
                    </div>
                </div>
                <div className="testPage" style={{width:'710px'}}>
                    <div className="topBtn_report">
                        <label style={{fontWeight: "600",marginBottom : "13px"}}>시작 날짜 : </label>  
                        <input 
                        style={{marginBottom : "0px"}}
                        type="date" 
                        value={startDate}
                        onChange={handleStartDateChange} 
                        />
                        <label style={{fontWeight: "600",marginBottom : "13px"}}> ~ 종료 날짜 : </label>
                        <input 
                        type="date" 
                        value={endDate}
                        onChange={handleEndDateChange} 
                        />
                    </div>
                    <div className="divtable">
                        {mergedData.length !== 0 ? (
                        <table>
                            <thead>
                                <th>시험지 이름</th>
                                <th>학생 이름</th>
                                <th>생성일</th>
                                <th>출제자</th>
                                <th>정답/오답</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {mergedData.map((data, index) => (
                                    <tr key={index} style={{ backgroundColor: indexToExclude.includes(index) ? 'orange' : 'white',textDecoration: indexToExclude.includes(index) ?'line-through':''}}>
                                        <td>{data.exam_name}</td>
                                        <td>{data.student.student_name}</td>
                                        <td>{formatDate(data.date)}</td>
                                        <td>{data.teacher_name}</td>
                                        <td>{data.score !== null ? (data.items - data.score) + ' / ' + data.score : '미채점' }</td>
                                        <td><button onClick={()=>excludeExam(index)} style={{fontSize:"18px"}}>제외</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>) : 
                        ('채점된 시험지가 없거나, 학생이 선택되지 않았습니다!') }
                    </div>
                </div>                    
            </div>
        </div>
    )
}