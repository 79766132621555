
import UserInfo from '../UserInfo/UserInfo.jsx'
import './Home.css';
import Modal from 'react-modal'
import { useState , useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { TbLock,TbLockCheck } from "react-icons/tb";
import Header from "../Header/Header"
import { IoMdClose } from "react-icons/io";



export default function Home(){
    const back_ip_url = process.env.REACT_APP_SERVER_URL
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [modalIsOpen3, setModalIsOpen3] = useState(false);
    const [modalIsOpen4, setModalIsOpen4] = useState(false);
    const [modalIsOpen5, setModalIsOpen5] = useState(false);
    const [pictureURL,setPictureURL] = useState();
    const userInfo = UserInfo().userInfo;    
    const [] = useState();


    useEffect(() => {
        const timer = setTimeout(() => {
            // 서버로부터 학생, 수강반 데이터 가져오기
            fetchPictureURL()
        }, 100); // 0.1초 뒤에 실행
        
        return () => clearTimeout(timer);
    }, [userInfo]);
    const fetchPictureURL = () =>{
        axios({
            method: 'post',
            url: `${back_ip_url}/fetchPictureURL`,  // 서버 엔드포인트
            withCredentials: true,
            data:{
              userId : userInfo.id
            }
          }).then((result) => {
            if (result.status === 200) {
                setPictureURL(result.data)
            }
          }
          ).catch(error => {
            console.log(error)
          })
    }

    //파일 업로드
    const onSelectFile = (e) => {
        const file = e.target.files[0];  // 선택된 파일
        const formData = new FormData();
        const uniqueFileName = `${userInfo.id}_img`;  // 고유한 파일 이름 생성
        formData.append('image', file);  // 'image' 필드에 파일 추가
        formData.append('fileName', uniqueFileName);  // 'fileName' 필드에 파일 이름 추가
        formData.append('user_id', userInfo.id);  // 'fileName' 필드에 파일 이름 추가
        console.log(file);
        console.log(uniqueFileName)
    
        // Axios를 사용한 POST 요청
        axios({
            method: 'post',
            withCredentials: true,
            url: `${back_ip_url}/uploadImage`,  // 서버 엔드포인트
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',  // 헤더 설정
            }
        }).then(response => {
            console.log('File uploaded successfully', response.data);
            fetchPictureURL()
            setModalIsOpen5(false)
            alert('변경완료')
            
        }).catch(error => {
            console.error('Error uploading file:', error);
        });
    };
    const uploadImage = () =>{

    }
    const check = () =>{
        console.log(1)
    }

    //이메일
    const [email,setEmail] = useState()
    const [emailMessage,setEmailMessage] = useState()
    const [isEmail,setIsEmail] = useState()

    const DupCheckEmail = () =>{
        axios({
          url: `${back_ip_url}/DupCheckEmail`,
          method: "POST",
          withCredentials: true,
          data:{
            email : email
          }
        }).then((result) => {
          if (result.status === 200) {
          setEmailMessage("사용 가능한 이메일 입니다.");
          setIsEmail(true);  
          }
        }
        ).catch(error => {
          setEmailMessage("다른 이메일을 사용해주세요.");
          setIsEmail(false)
        })
       }

    const onChangeEmail = (e) => {
        const currentEmail = e.target.value;
        setEmail(currentEmail);
        const emailRegExp =
          /^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;
      
        if (!emailRegExp.test(currentEmail)) {
          setEmailMessage("이메일의 형식이 올바르지 않습니다!");
          setIsEmail(false);
        } else {
          setEmailMessage("사용 가능한 이메일 입니다.");
          setIsEmail(true);
        }
      }

      useEffect(() => {
        const emailRegExp =
          /^[A-Za-z0-9_]+[A-Za-z0-9]*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;
      
        if (!emailRegExp.test(email)) {
          setEmailMessage("이메일의 형식이 올바르지 않습니다!");
          setIsEmail(false);
        } else {
          DupCheckEmail();
        }
      }, [email]);
    
    const changeEmail = () =>{
        axios({
            url : `${back_ip_url}/changeEmail`,
            data:{
                email : email,
                id : userInfo.id,
            },
            withCredentials: true,
            method: "POST"
        }).then(response =>{
            if (response.status === 200){
                alert("이메일 변경 완료!");
                document.getElementById("inputElement2").value = ""
                setEmailMessage(null)
            }
        })
    }
    //비밀번호
    const [passwordConfirmMessage,setPasswordConfirmMessage] = useState();
    const [isPasswordConfirm,setIsPasswordConfirm] = useState();
    const [password,setPassword] = useState();
    const [passwordC,setPasswordC] = useState();
    

    const onChangePasswordConfirm = (e) => {
        const currentPasswordConfirm = e.target.value;
        if (password !== currentPasswordConfirm) {
          setPasswordConfirmMessage("비밀번호가 똑같지 않습니다!");
          setIsPasswordConfirm(false);
        } else {
          setPasswordConfirmMessage("똑같은 비밀번호를 입력했습니다.");
          setIsPasswordConfirm(true);
        }
      };
    
    const changePassword = () => {
        axios({
            url : `${back_ip_url}/changePassword`,
            data:{
                password : password,
                id : userInfo.id,
            },
            withCredentials: true,
            method: "POST"
        }).then(response =>{
            if (response.status === 200){
                alert("비밀번호 변경 완료!");
                document.getElementById("inputElement").value = ""
                setPasswordConfirmMessage(null)
            }
        })
    }

    //비밀번호 확인
    const [isPass,setIsPass] = useState(false)
    const openEditWindow = () =>{
        setModalIsOpen(false)
        setModalIsOpen2(true)
    }
    useEffect(() => {
        setIsPass(false);
    }, [modalIsOpen]);
    const checkPW = () => {
        axios({
            url : `${back_ip_url}/checkPW`,
            data:{
                password : passwordC,
                id : userInfo.id,
            },
            withCredentials: true,
            method: "POST"
        }).then(response =>{
            if (response.status === 200){
                openEditWindow()

            }else if(response.status === 202){
                alert("비밀번호를 다시 확인해주세요")
                setIsPass(false)
            }
        })
        .catch(error => {
            // 서버 오류 처리
            console.error('서버 오류:', error);
        });
    }
    //회원정보수정 선택
    let editName = "정보 수정 선택"
    const [visibleId, setVisibleId] = useState();
    const chooseEdit = (mode) =>{
        if(mode == 1){
            setModalIsOpen2(false)
            setModalIsOpen3(true)
        }
        if(mode == 2){
            setModalIsOpen2(false)
            setModalIsOpen4(true)
        }
        if(mode == 3){
            setModalIsOpen2(false)
            setModalIsOpen5(true)
        }
    }

    return(
    <div>
        <Modal className='Modal_s2' isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                    <div className='Modal_head'>
                        <h2>회원정보수정</h2>
                        <button className='xMark' onClick={()=>setModalIsOpen(false)}><IoMdClose /></button>
                    </div>
                    <div className='chooseEdit'>
                        <div style={{display:'flex'}}>
                            <h3>비밀번호 확인</h3>
                            <TbLock className='iconA'/>
                        </div>
                        <div>
                            <input className='inputA' type='password' onChange={(e) => setPasswordC(e.target.value)} placeholder='비밀번호' style={{width:'260px'}}></input>
                        </div>
                        <button className='buttonA btnColorA'onClick={checkPW}>확인</button>
                    </div>
            </Modal>
            <Modal className='Modal_s2' isOpen={modalIsOpen2}>
                <div className='Modal_head'>
                        <h2>{editName}</h2>
                        <button className='xMark' onClick={()=>setModalIsOpen2(false)}><IoMdClose /></button>
                </div>
                <div className='editbox'>
                    <div className='chooseEdit'>
                        <button className='buttonA btnColorA'onClick={()=>chooseEdit(1)}>이메일 변경</button>
                        <button className='buttonA btnColorA'onClick={()=>chooseEdit(2)}>비밀번호 변경</button>
                        <button className='buttonA btnColorA'onClick={()=>chooseEdit(3)}>학원 사진 추가/변경</button>
                    </div>
                </div>
            </Modal>
            <Modal className='Modal_Score' isOpen={modalIsOpen3}>
                <div className='Modal_head'>
                        <h2>이메일 변경</h2>
                        <button className='xMark' onClick={()=>setModalIsOpen3(false)}><IoMdClose /></button>
                </div>
                <div className='chooseEdit'>
                    <input className='inputA' onChange={onChangeEmail} id='inputElement2' placeholder='변경할 이메일' style={{width:'260px'}}></input>
                    <p className={`message ${!isEmail ? 'no' : 'ok'}`}>{emailMessage}</p>
                    <button  className='buttonA btnColorA' onClick={changeEmail}>이메일 변경 확인</button>
                </div>
            </Modal>
            <Modal className='Modal_Score' isOpen={modalIsOpen4}>
                <div className='Modal_head'>
                        <h2>비밀번호 변경</h2>
                        <button className='xMark' onClick={()=>setModalIsOpen4(false)}><IoMdClose /></button>
                </div>
                <div className='chooseEdit'>
                    <input className='inputA' style={{width:'260px'}} placeholder='변경할 비밀번호' id='inputElement' type='password' minLength={4} maxLength={20} 
                    required onChange={(e) => setPassword(e.target.value)}></input>
                    <input className='inputA' style={{width:'260px'}} placeholder='변경할 비밀번호 확인' id='inputElement' type='password' 
                    onChange={onChangePasswordConfirm}required></input>
                    <p className={`message ${!isPasswordConfirm ? 'no' : 'ok'}`}>{passwordConfirmMessage}</p>
                    <button  className='buttonA btnColorA' onClick={changePassword}>비밀번호 변경 확인</button>
                </div>
            </Modal>
            <Modal className='Modal_Score' isOpen={modalIsOpen5}>
                <div className='Modal_head'>
                        <h2>학원 사진 추가/변경</h2>
                        <button className='xMark' onClick={()=>setModalIsOpen5(false)}><IoMdClose /></button>

                </div>
                <div className='chooseEdit'>
                    <input type='file' name='images' onChange={onSelectFile} accept='.png, .jpg,image/*'/>
                    <div className=''>파일선택시 자동으로 추가/변경됩니다 신중하게 선택해주세요</div>
                </div>
            </Modal>
            <div className='main_container'>
            <Header></Header>
            <div className='info_container'>
                <div className='pic'>
                    <img alt='프사' src={`${back_ip_url}/uploads/${pictureURL}`}/>
                </div> 
                <div className='home_info'>
                    <p>{userInfo.name} 님</p>
                    <button className="buttonC btnColorA" onClick={()=>setModalIsOpen(true)}>내 정보 수정</button>
                    {userInfo.id == "MK0927"||"MK0410" ? (<button className="buttonC btnColorA" style={{background:"#413b97"}}><Link to='/Admin' >뭉클 회원 관리</Link></button>):("")}
                </div>
            </div>
            <div className='btn_container'>
                <a href="/ItemManagement" className='btn btn1'>문제 관리</a>
                <a href="/ClassManagement" className='btn btn2'>클래스 관리</a>
                <a href="/ExamReport" className='btn btn3'>성적 관리</a>
                <a href="/TestCreation" className='btn btn4'>시험지 출제</a>
            </div>
        </div>
    </div>    
        
    )
    
}
