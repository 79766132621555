
import React, { useState , useEffect , useRef, useCallback} from 'react';
import './TreeView2.css';
import axios from 'axios';
import UserInfo from '../UserInfo/UserInfo.jsx'
import { FaFileAlt } from "react-icons/fa";
import { FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import { FaSquare, FaCheckSquare, FaMinusSquare ,} from "react-icons/fa";
import { IoMdArrowDropright,IoMdClose} from "react-icons/io";
import { CiSaveDown2 } from "react-icons/ci";
import { LuMinusSquare,LuPlusSquare,LuSquareSlash  } from "react-icons/lu";
import { GrPowerReset } from "react-icons/gr";

export default function TestInfo({ closeModal , chosenItem2 , setChosenItem2, treeData2 , prevModal}){
    const back_ip_url = process.env.REACT_APP_SERVER_URL
    const [newTreeName,setNewTreeName] = useState('');
    const userInfo = UserInfo().userInfo;
    const isLogin = UserInfo().isLogin;
    const [checkedTreeId, setCheckedTreeId] = useState(0)
    const [treeData, setTreeData] = useState(null);
    const [treeDataOrigin, setTreeDataOrigin] = useState(null);
    const [examList, setExamList] = useState([]);
    const [count,setCount] = useState(0);
    const isFirstRender = useRef(true);


    //현재 날짜
    const [currentDate, setCurrentDate] = useState('');
    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD' 형식으로 포맷팅
        setCurrentDate(formattedDate);
    }, []);

    useEffect(() => {
        if (isLogin) {  // 로그인 상태인지 확인
        const timer = setTimeout(() => {
            // 서버로부터 학생, 수강반 데이터 가져오기
            fetchTreeData2();
            fetchExamData();
            setUserName(userInfo.name)
        }, 100); // 0.1초 뒤에 실행
        
        return () => clearTimeout(timer);}
    }, [userInfo]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        fetchTreeData2();
    }, [count]);
    //트리 데이터 가져오기
    const fetchTreeData = () => {
        // 서버로 Axios 요청 보내기
        axios({
        method: "POST",
            url: `${back_ip_url}/getTreeData`,
            data: {userId : userInfo.id},
            withCredentials : true,
        })
        .then(response => {
            setTreeData(response.data);
            setTreeDataOrigin(response.data)
        })
        .catch(error => {
            console.error('Error fetching tree data:', error);
        });
    };
    //트리 데이터 가져오기
    const fetchTreeData2 = () => {
        if(userInfo.sharing == 1){
            axios({
                method: "POST",
                    url: `${back_ip_url}/getTreeData2`,
                    data: {
                        userId : userInfo.id,
                        sharing : userInfo.sharing,
                        sharing2 : userInfo.sharing2
                    },
                    withCredentials : true,
                })
                .then(response => {
                    setTreeData(response.data);
                    setTreeDataOrigin(response.data)
                })
                .catch(error => {
                    console.error('Error fetching tree data:', error);
                });
        }else{
            axios({
                method: "POST",
                    url: `${back_ip_url}/getTreeData`,
                    data: {userId : userInfo.id},
                    withCredentials : true,
                })
                .then(response => {
                    setTreeData(response.data);
                    setTreeDataOrigin(response.data)
                })
                .catch(error => {
                    console.error('Error fetching tree data:', error);
                });
        }
        
    };
     //exam
     const fetchExamData = () =>{
        axios({
            method: "POST",
              url: `${back_ip_url}/fetchExamData`,
              data: {
                userId : userInfo.id
                },
              withCredentials : true,
          })
          .then(response => {
            const { examList } = response.data;
            setExamList(examList);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }
    
    //이름 입력
    const newExamNameRef = useRef('');
    const newUserNameRef = useRef('');

    const handleInputExamname = (e) => {
        newExamNameRef.current = e.target.value;
    };
    const handleInputUsername = (e) => {
        newUserNameRef.current = e.target.value;
    };
     //선택된문제
     let totalItem = 0
     let totalOb = 0
     let totalSb = 0
     const [chosenItem,setChosenItem] = useState([])
     const [objectiveItem1,setObjectiveItem1] = useState(0)
     const [subjectiveItem1,setSubjectiveItem1] = useState(0)
     const [objectiveItem2,setObjectiveItem2] = useState(0)
     const [subjectiveItem2,setSubjectiveItem2] = useState(0)
     const [objectiveItem3,setObjectiveItem3] = useState(0)
     const [subjectiveItem3,setSubjectiveItem3] = useState(0)
     const [objectiveItem4,setObjectiveItem4] = useState(0)
     const [subjectiveItem4,setSubjectiveItem4] = useState(0)
     const [objectiveItem5,setObjectiveItem5] = useState(0)
     const [subjectiveItem5,setSubjectiveItem5] = useState(0)
     const [examName,setExamName] = useState()
     const [userName,setUserName] = useState(userInfo.name)
     totalOb =  objectiveItem1+objectiveItem2+objectiveItem3+objectiveItem4+objectiveItem5
     totalSb = subjectiveItem1+subjectiveItem2+subjectiveItem3+subjectiveItem4+subjectiveItem5
     totalItem = totalOb + totalSb
 
 
     const addChosenItem = () => {
     setChosenItem((prevChosenItem) => {
         const updatedChosenItem = new Set([...prevChosenItem, ...Array.from(checkedTreeId)]);
         const updatedArray = Array.from(updatedChosenItem);
         
         // CheckNumberOfItem 호출
         CheckNumberOfItem(updatedArray);
         
         return updatedArray;
     });
     };
     const getTreeNameById = (id) => {
         const treeItem = treeData ? treeData.find(tree => tree.id === id && tree.style_type === 1):null;
         console.log(treeItem)
         return treeItem ? treeItem.name : null;
     };
     
     const resetChosenItem = () => {
         setChosenItem2([])
         resetAllCounts()
         prevModal()
     }
 
 
     //출제방법 버튼
         const [selectedMultiCol, setSelectedMultiCol] = useState(0);
         const handleMultiColClick = () => {
             setSelectedMultiCol(prevState => (prevState === 1 ? 0 : 1));
         }
 
 
         const [selectedTemp,setSelectedTemp] = useState(1);
         const handleTempClick = (option) => {
             setSelectedTemp(option);
           }
 
         const [selectedOption, setSelectedOption] = useState(null);
         const handleOptionClick = (option) => {
           setSelectedOption(option);
           CheckNumberOfItem(chosenItem2)
           setDifficulty([])
         }
     
     //보유 문제수 확인 함수
     const [checkedItemData,setCheckedItemData] = useState([])
 
     const CheckNumberOfItem =(index)=>{
         axios({
             url : `${back_ip_url}/CheckNumberOfItem`,
             data : {
                 index : index,
                 userId : userInfo.id
             },
             method : "POST",
             withCredentials : true,
         }).then(response=>{
             if (response.status === 200){
                 setCheckedItemData(response.data)
             }
         }).catch(error => {
             if (error.status === 400){
                 console.error('Error:', error)
                 alert("실패")
             }
         }) 
     }
 
 
         //객관식,주관식 난이도별 갯수
         const [difficulty1Count, setDifficulty1Count] = useState(0);
         const [difficulty2Count, setDifficulty2Count] = useState(0);
         const [difficulty3Count, setDifficulty3Count] = useState(0);
         const [difficulty4Count, setDifficulty4Count] = useState(0);
         const [difficulty5Count, setDifficulty5Count] = useState(0);
 
         const [difficulty1CountO, setDifficulty1CountO] = useState(0);
         const [difficulty2CountO, setDifficulty2CountO] = useState(0);
         const [difficulty3CountO, setDifficulty3CountO] = useState(0);
         const [difficulty4CountO, setDifficulty4CountO] = useState(0);
         const [difficulty5CountO, setDifficulty5CountO] = useState(0);
 
         const [difficulty1CountS, setDifficulty1CountS] = useState(0);
         const [difficulty2CountS, setDifficulty2CountS] = useState(0);
         const [difficulty3CountS, setDifficulty3CountS] = useState(0);
         const [difficulty4CountS, setDifficulty4CountS] = useState(0);
         const [difficulty5CountS, setDifficulty5CountS] = useState(0);
 
         const resetAllCounts = () => {
             setDifficulty1Count(0);
             setDifficulty2Count(0);
             setDifficulty3Count(0);
             setDifficulty4Count(0);
             setDifficulty5Count(0);
     
             setDifficulty1CountO(0);
             setDifficulty2CountO(0);
             setDifficulty3CountO(0);
             setDifficulty4CountO(0);
             setDifficulty5CountO(0);
     
             setDifficulty1CountS(0);
             setDifficulty2CountS(0);
             setDifficulty3CountS(0);
             setDifficulty4CountS(0);
             setDifficulty5CountS(0);
         };
     useEffect(() => {
         const difficulty1_O = checkedItemData.filter(item => item.difficulty_level === 1 && 
             ['①', '②', '③', '④', '⑤'].includes(item.answer_text));
         const difficulty2_O = checkedItemData.filter(item => item.difficulty_level === 2 && 
             ['①', '②', '③', '④', '⑤'].includes(item.answer_text));
         const difficulty3_O = checkedItemData.filter(item => item.difficulty_level === 3 && 
             ['①', '②', '③', '④', '⑤'].includes(item.answer_text));
         const difficulty4_O = checkedItemData.filter(item => item.difficulty_level === 4 && 
             ['①', '②', '③', '④', '⑤'].includes(item.answer_text));
         const difficulty5_O = checkedItemData.filter(item => item.difficulty_level === 5 && 
             ['①', '②', '③', '④', '⑤'].includes(item.answer_text));
 
         setDifficulty1CountO(difficulty1_O.length);
         setDifficulty2CountO(difficulty2_O.length);
         setDifficulty3CountO(difficulty3_O.length);
         setDifficulty4CountO(difficulty4_O.length);
         setDifficulty5CountO(difficulty5_O.length);
 
         
         const difficulty1_S = checkedItemData.filter(item => item.difficulty_level === 1 && 
             !['①', '②', '③', '④', '⑤'].includes(item.answer_text));   
         const difficulty2_S = checkedItemData.filter(item => item.difficulty_level === 2 && 
             !['①', '②', '③', '④', '⑤'].includes(item.answer_text));   
         const difficulty3_S = checkedItemData.filter(item => item.difficulty_level === 3 && 
             !['①', '②', '③', '④', '⑤'].includes(item.answer_text));   
         const difficulty4_S = checkedItemData.filter(item => item.difficulty_level === 4 && 
             !['①', '②', '③', '④', '⑤'].includes(item.answer_text));  
         const difficulty5_S = checkedItemData.filter(item => item.difficulty_level === 5 && 
             !['①', '②', '③', '④', '⑤'].includes(item.answer_text));   
 
         setDifficulty1CountS(difficulty1_S.length);
         setDifficulty2CountS(difficulty2_S.length);
         setDifficulty3CountS(difficulty3_S.length);
         setDifficulty4CountS(difficulty4_S.length);
         setDifficulty5CountS(difficulty5_S.length);
 
         setDifficulty1Count(difficulty1_O.length+difficulty1_S.length)
         setDifficulty2Count(difficulty2_O.length+difficulty2_S.length)
         setDifficulty3Count(difficulty3_O.length+difficulty3_S.length)
         setDifficulty4Count(difficulty4_O.length+difficulty4_S.length)
         setDifficulty5Count(difficulty5_O.length+difficulty5_S.length)
     }, [checkedItemData]);
 
     //시험지 저장
         const getIdsWithStyleType1 = (chosenItem, treeData) => {
             return chosenItem.filter(id => {
                 const treeItem = treeData.find(tree => tree.id === id && tree.style_type === 1);
                 return treeItem !== undefined;
             });
         };
         //폴더를 제외한 유형만 저장된 ID
         const filteredIds = getIdsWithStyleType1(chosenItem2, treeData2);
 
     const saveExam = () =>{
         const ExamNameRef = newExamNameRef.current
         const UserNameRef = newUserNameRef.current
 
         axios({
             url : `${back_ip_url}/examCreation`,
             data : {
                 name : ExamNameRef,
                 objectiveItem : [objectiveItem1,objectiveItem2,objectiveItem3,objectiveItem4,objectiveItem5],
                 subjectiveItem : [subjectiveItem1,subjectiveItem2,subjectiveItem3,subjectiveItem4,subjectiveItem5],
                 date : currentDate,
                 username : UserNameRef,
                 tree_id : filteredIds,
                 userId : userInfo.id,
                 temp_id : selectedTemp,
                 multi_col : selectedMultiCol
             },
             method : "POST",
             withCredentials : true,
         }).then(response=>{
             if (response.status === 200){
                 alert("신규 시험지 추가 완료")
                 setCount(count+1)
                 closeModal()
             }
         }).catch(error => {
             if (error.status === 400){
                 console.error('Error:', error)
                 alert("실패")
             }
         }) 
     }
     //유형별 출제
     const [difficulty, setDifficulty] = useState([]);
     const difficultyLabels = ["하", "중", "상", "최상", "기술"];
 
 
     const handleCheckboxChange = (event) => {
         const value = parseInt(event.target.value);
         if (event.target.checked) {
             // Add value to difficulty array
             setDifficulty(prevDifficulty => [...prevDifficulty, value]);
         } else {
             // Remove value from difficulty array
             setDifficulty(prevDifficulty => prevDifficulty.filter(item => item !== value));
         }
     };
 
     let totalItem_style2 = 0
     const [SYN,setSYN] = useState(0)
     const [style2_Item,setStyle2_Item] = useState(0)
     const [AllowedSameItem,setAllowedSameItem] = useState(0)
 
     const today = new Date();
     const threeMonthsAgo = new Date(today);
     threeMonthsAgo.setMonth(today.getMonth() - 3);
   
     const formatDate = (dateString) => {
         if (!dateString) {
             return ''; // dateString이 null이나 undefined인 경우 빈 문자열 반환
         }
     
         const date = new Date(dateString); // Date 객체로 변환
     
         // UTC 시간을 9시간 더해서 한국 시간대로 변환
         const kstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
     
         const year = kstDate.getFullYear();
         const month = String(kstDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
         const day = String(kstDate.getDate()).padStart(2, '0');
     
         return `${year}-${month}-${day}`; // 'YYYY-MM-DD' 형식으로 반환
     };
     const [startDate, setStartDate] = useState(formatDate(threeMonthsAgo));
     const [endDate, setEndDate] = useState(formatDate(today));
 
     const handleCheckboxChange2 = (event) => {
         setSYN(event.target.checked ? 1 : 0);
       };
     totalItem_style2 = style2_Item * filteredIds.length
     
     const handleCheckboxChange3 = (event) => {
         setAllowedSameItem(event.target.checked ? 1 : 0);
       };
     
     //중복 제거 기간내 선택된 시험지 정보
     const ExamListWithDate = examList.filter(exam => {
         const examDate = new Date(exam.date);
         return examDate >= new Date(startDate) && examDate <= new Date(endDate);
         });
     
     const saveExam2 = () =>{
         const ExamNameRef = newExamNameRef.current
         const UserNameRef = newUserNameRef.current
         axios({
             url : `${back_ip_url}/examCreation2`,
             data : {
                 name : ExamNameRef,
                 itemNumber : style2_Item,
                 difficulty : difficulty,
                 subjectYN : SYN,
                 date : currentDate,
                 username : UserNameRef,
                 tree_id : filteredIds,
                 userId : userInfo.id,
                 temp_id : selectedTemp,
                 multi_col : selectedMultiCol
             },
             method : "POST",
             withCredentials : true,
         }).then(response=>{
             if (response.status === 200){
                 alert("신규 시험지 추가 완료")
                 setCount(count+1)
                 closeModal()
             }
         }).catch(error => {
             if (error.status === 400){
                 console.error('Error:', error)
                 alert("실패")
             }
         }) 
     }
     const saveExam3 = () =>{
         const ExamNameRef = newExamNameRef.current
         const UserNameRef = newUserNameRef.current
         axios({
             url : `${back_ip_url}/examCreation3`,
             data : {
                 name : ExamNameRef,
                 date : currentDate,
                 username : UserNameRef,
                 tree_ids : filteredIds,
                 userId : userInfo.id,
                 temp_id : selectedTemp,
                 multi_col : selectedMultiCol
             },
             method : "POST",
             withCredentials : true,
         }).then(response=>{
             if (response.status === 201){
             var url = new URL('http://localhost:8000/manage_question/selectElement');
             var params = { 
                         user_name : userInfo.id,
                         unit_list : filteredIds,
                         exam_id : response.data.examId
              };
             fetch(url,{
                 method : 'POST',
                 headers: {
                     'Content-Type': 'application/json; charset=utf-8'
                 },
                 body: JSON.stringify(params)
             })
             .then(response => response.text())
             .then(data => alert(data))
             .catch(error => {console.error('Error:', error)
                 alert('오류발생 : 파이썬 실행 상태를 확인해주세요')
             })
             }
         }).catch(error => {
             if (error.status === 500){
                 console.error('Error:', error)
                 alert("실패")
             }
         }) 
     }
     const saveExam4 = () =>{
         const ExamNameRef = newExamNameRef.current
         const UserNameRef = newUserNameRef.current
         axios({
             url : `${back_ip_url}/examCreation4`,
             data : {
                 name : ExamNameRef,
                 difficulty : difficulty,
                 subjectYN : SYN,
                 date : currentDate,
                 ExamListWithDate : ExamListWithDate,
                 username : UserNameRef,
                 tree_id : filteredIds,
                 userId : userInfo.id,
                 temp_id : selectedTemp,
                 multi_col : selectedMultiCol,
                 itemNumber : style2_Item,
             },
             method : "POST",
             withCredentials : true,
         }).then(response=>{
             if (response.status === 200){
                 alert("신규 시험지 추가 완료")
                 setCount(count+1)
                 closeModal()
             }
         }).catch(error => {
             if (error.status === 500){
                 console.error('Error:', error)
                 alert("실패")
             }
         }) 
     }
     // 트리 컴포넌트
   const CheckBoxIcon = ({ variant, ...rest }) => {
     switch (variant) {
       case "all":
         return <FaCheckSquare {...rest} />;
       case "none":
         return <FaSquare {...rest} />;
       case "some":
         return <FaMinusSquare {...rest} />;
       default:
         return null;
     }
   };
 
   const ArrowIcon = ({ isOpen, isBranch }) => {
     if(isBranch){
         switch(isOpen){
             case true:
                 return <LuMinusSquare/>
             case false:
                 return <LuPlusSquare />
         }
     }else{
         return <LuSquareSlash style={{opacity:"0.5"}}/> 
     }
   };
   const FolderIcon = ({ isOpen, user_id }) => {
     const color = user_id == 'MK0927' ? '#413b97' : '#e8a87c';
     const Icon = isOpen ? FaRegFolderOpen : FaRegFolder;
 
     return <Icon color={color} className="icon" />;
 };
 
   
     const FileIcon = ({ style , user_id}) => {
         const style_type = style
         switch (style_type) {
           case 1:
             return <FaFileAlt  color="rgb(122, 124, 255)" className="icon" />;
         case "hwpx":
             return <FaFileAlt  color="rgb(122, 124, 255)" className="icon" />;    
           default:
             return <FolderIcon user_id={user_id}/>;
         }
         }

    const LogCheck = () =>{
        console.log("chosenItem2",chosenItem2)
        console.log("treeData",treeData)
    }
return (
    <div className='treeBtn2 block'  id='create'>
            <div className='Modal_head' style={{width:"100%", margin: "0px"}}>
                <h3>시험지 이름 및 문항수,난이도 설정</h3>
                <button className='xMark' style={{margin:"0px",marginTop:"5px" , fontSize:"20px"}} onClick={closeModal}><IoMdClose /></button>
            </div>


                <div className='testInfo'>
                    <div><p style={{width : "170px"}}>현재 날짜:</p>{currentDate} </div>
                    <div><p>시험지 이름 :</p><input className="inputA" onChange={handleInputExamname} style={{width:"65%"}} placeholder={"시험지 이름을 입력해주세요"}></input></div>
                    <div><p>출제자 이름 :</p><input className="inputA" onChange={handleInputUsername} style={{width:"65%"}} placeholder={"출제자 이름을 입력해주세요"}></input></div>
                    <div>
                        <p>템플릿 선택:</p> 
                        <button
                        className='buttonA'
                        onClick={() => handleTempClick(1)}
                        style={{
                            borderColor: selectedTemp === 1 ? '#413b97' : 'white',height : '30px',borderWidth: selectedTemp === 1 ? '3px' : '1px',margin : '3px'
                        }}
                        >1번</button>

                        <button
                        className='buttonA'
                        onClick={() => handleTempClick(2)}
                        style={{
                            borderColor: selectedTemp === 2 ? '#413b97' : 'white',height : '30px',borderWidth: selectedTemp === 2 ? '3px' : '1px',margin : '3px'
                        }}
                        >2번</button>

                        <button
                        className='buttonA'
                        onClick={() => handleTempClick(3)}
                        style={{
                            borderColor: selectedTemp === 3 ? '#413b97' : 'white',height : '30px',borderWidth: selectedTemp === 3 ? '3px' : '1px',margin : '3px'
                        }}
                        >3번</button>
                    </div>
                    <div>
                        <p>다단 줄 생성 여부 : </p>
                        <button
                        className='buttonA'
                        onClick={() => handleMultiColClick()}
                        style={{
                        height : '30px',borderColor: selectedMultiCol === 1 ? '#413b97' : 'white',borderWidth: selectedMultiCol === 1 ? '3px' : '1px',margin : '3px',width: "65%"
                        }}
                    >{selectedMultiCol === 1 ? '사용' : '사용안함'}</button>
                    </div>
                </div>
                <div className='testInfo2'>
                    <p style={{width : '160px'}}>출제 유형 :</p>
                    <button
                        className='buttonA'
                        onClick={() => handleOptionClick('option1')}
                        style={{
                            height : '30px',borderColor: selectedOption === 'option1' ? '#413b97' : 'white',borderWidth: selectedOption === 'option1' ? '3px' : '1px',margin : '3px'
                        }}
                    >난이도별</button>
                    <button
                        className='buttonA'
                        onClick={() => handleOptionClick('option2')}
                        style={{
                            height : '30px',borderColor: selectedOption === 'option2' ? '#413b97' : 'white',borderWidth: selectedOption === 'option2' ? '3px' : '1px',margin : '3px'
                        }}
                    >유형별</button>
                    <button
                        className='buttonA'
                        onClick={() => handleOptionClick('option3')}
                        style={{
                            height : '30px',borderColor: selectedOption === 'option3' ? '#413b97' : 'white',borderWidth: selectedOption === 'option3' ? '3px' : '1px',margin : '3px'
                        }}
                    >문제 선택</button>
                </div>
                <div style={{marginLeft: "160px"}}>
                    {selectedOption === 'option1' && 
                        <div>
                            <p style={{fontWeight:"700"}}>난이도별 출제 : 출제 문항수</p>
                            <div className='difficulty_name'>
                                <p>기술</p>
                                <input type="number" className="shortInput" placeholder="객관식" onChange={(e) => setObjectiveItem5(Number(e.target.value))}/>문항 /
                                <input type="number" className="shortInput" placeholder="주관식" onChange={(e) => setSubjectiveItem5(Number(e.target.value))}/>문항
                                (객:{difficulty5CountO} , 주:{difficulty5CountS})
                            </div>
                            <div className='difficulty_name'>
                                <p>최상</p>
                                <input type="number" className="shortInput" placeholder="객관식" onChange={(e) => setObjectiveItem4(Number(e.target.value))}/>문항 / 
                                <input type="number" className="shortInput" placeholder="주관식" onChange={(e) => setSubjectiveItem4(Number(e.target.value))}/>문항
                                (객:{difficulty4CountO} , 주:{difficulty4CountS})
                            </div>
                            <div className='difficulty_name'>
                                <p>상</p>
                                <input type="number" className="shortInput" placeholder="객관식" onChange={(e) => setObjectiveItem3(Number(e.target.value))}/>문항 / 
                                <input type="number" className="shortInput" placeholder="주관식" onChange={(e) => setSubjectiveItem3(Number(e.target.value))}/>문항
                                (객:{difficulty3CountO} , 주:{difficulty3CountS})
                            </div>
                            <div className='difficulty_name'>
                                <p>중</p>
                                <input type="number" className="shortInput" placeholder="객관식" onChange={(e) => setObjectiveItem2(Number(e.target.value))}/>문항 /
                                <input type="number" className="shortInput" placeholder="주관식" onChange={(e) => setSubjectiveItem2(Number(e.target.value))}/>문항
                                (객:{difficulty2CountO} , 주:{difficulty2CountS})
                            </div>
                            <div className='difficulty_name'>
                                <p>하</p>
                                <input type="number" className="shortInput" placeholder="객관식" onChange={(e) => setObjectiveItem1(Number(e.target.value))}/>문항 / 
                                <input type="number" className="shortInput" placeholder="주관식" onChange={(e) => setSubjectiveItem1(Number(e.target.value))}/>문항
                                (객:{difficulty1CountO} , 주:{difficulty1CountS})
                            </div>
                            <h4>
                                총 문제수 {totalItem} , 객관식 {totalOb} , 주관식 {totalSb}
                            </h4>
                        </div>}
                    {selectedOption === 'option2' && 
                        <div className='testInfo3'>
                            <div style={{fontWeight:"700"}}>출제 가능 문제수 : 하({difficulty1Count}),중({difficulty2Count}),상({difficulty3Count}),최상({difficulty4Count}),기술({difficulty5Count})</div>
                            <div>난이도 : 
                                {difficultyLabels.map((label, index) => (
                                <label key={index}>
                                    <input
                                        className="inputCheck"
                                        type="checkbox"
                                        value={index + 1}
                                        onChange={handleCheckboxChange}
                                    /> {label}
                                </label>))}
                            </div>
                            <div>
                            유형별 문항수 : <input type="number" className="shortInput" placeholder="유형별" onChange={(e) => setStyle2_Item(Number(e.target.value))}/>문항
                            </div>
                            <div>
                                주관식 출제 여부 :
                                <input className="inputCheck" type="checkbox" checked={SYN === 1} onChange={handleCheckboxChange2}/>체크시 주관식 포함
                            </div>
                            <div>
                                기간내 중복문제 제거 :
                                <input className="inputCheck" type="checkbox" checked={AllowedSameItem === 1} onChange={handleCheckboxChange3}/>기간내 중복문제 제외
                            </div>
                            {AllowedSameItem === 1 && (
                                <div>
                                <div>
                                    시작 날짜:
                                    <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div>
                                    종료 날짜:
                                    <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                                </div>
                            )}
                            <h4>
                                총 문제수 : {totalItem_style2} 문항
                            </h4>
                        </div>}
                    {selectedOption === 'option3' && <div style={{fontWeight:"700"}}>문제 선택 출제</div>}
                </div>
                <div style={{display:"flex", alignItems:"center"}}>
                    <h3 style={{width:"158px"}}>선택된 유형 : </h3>
                    <button className="buttonA btnColorA" style={{width: "64%"}} onClick={resetChosenItem}>유형 재선택<GrPowerReset className='iconB' style={{fontSize: "20px"}}/></button>
                </div>
                <ul className='examCreation'>
                    {chosenItem2.map((item, index) => (
                        <li key={index}>
                            {getTreeNameById(item)}
                        </li>
                    ))}
                </ul>
                {selectedOption === 'option1' && <button className="buttonB btnColorA confirm_btn" onClick={saveExam} disabled={chosenItem2.length === 0 || newExamNameRef === '' || totalItem == 0} style={{marginLeft:"160px" , width:"385px"}}><CiSaveDown2 className='iconA'/> 시험지 저장</button>}
                {selectedOption === 'option2' && AllowedSameItem === 0 && <button className="buttonB btnColorA confirm_btn" onClick={saveExam2} disabled={difficulty.length === 0 || newExamNameRef === '' || totalItem_style2 == 0} style={{marginLeft:"160px" , width:"385px"}}><CiSaveDown2 className='iconA'/> 시험지 저장</button>}
                {selectedOption === 'option3' && <button className="buttonB btnColorA confirm_btn" onClick={saveExam3} disabled={!difficulty || newExamNameRef === '' || chosenItem2.length === 0 } style={{marginLeft:"160px" , width:"385px"}}><CiSaveDown2 className='iconA'/> 시험지 저장</button>}
                {selectedOption === 'option2' && AllowedSameItem === 1 && <button className="buttonB btnColorA confirm_btn" onClick={saveExam4} disabled={chosenItem2.length === 0 ||!difficulty || newExamNameRef === ''}><CiSaveDown2 className='iconA' style={{marginLeft:"160px" , width:"385px"}}/> 시험지 저장</button>}
                {/* <button onClick={LogCheck}>log check</button> */}
        </div>
)
}