import axios from 'axios';
import { useState , useEffect } from 'react';
import './StudentMarking.css';
import Modal from 'react-modal'
import { IoMdClose } from "react-icons/io";
import { FaRegTimesCircle ,FaRegCheckCircle } from "react-icons/fa";


export default function StudentMarking(){

    const back_ip_url = process.env.REACT_APP_SERVER_URL

    const [examList, setExamList] = useState([]);
    const [filteredExamList,setFilteredExamList] = useState([]);
    const [examQuestions, setExamQuestions] = useState([]);
    const [examStudents, setExamStudents] = useState([]);
    const [workbook,setWorkbook] = useState([])
    const [workbookStudent,setWorkbookStudent] = useState([])

    const [selectedRow, setSelectedRow] = useState();
    const [selectedRow2, setSelectedRow2] = useState();


    const handleRowClick = (index) => {
        setSelectedRow(index);
    }
    const handleRowClick2 = (index) => {
        setSelectedRow2(index);
    }
    function getCookie(name) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return decodeURIComponent(cookie.substring(name.length+1));
            }
        }
        return null;
    }

    const [studentInfo, setStudentInfo] = useState();
    const [count,setCount] = useState();

    const closeModal1 = () =>{
        setModalIsOpen1(false)
        setColorX([])
        setColorO([])
    }
    const closeModal2 = () =>{
        setModalIsOpen2(false)
        setColorX([])
        setColorO([])
    }

    useEffect(() => {
        // 쿠키에서 사용자 정보 가져오기
        const studentInfoCookie = getCookie('studentInfo'); // 쿠키 이름 수정
        console.log(studentInfoCookie)
        if (studentInfoCookie) {
            try {
                // JSON.parse 처리
                const parsedUserInfo = JSON.parse(studentInfoCookie.substring(2));
                setStudentInfo(parsedUserInfo);
            } catch (error) {
                console.error('Error parsing studentInfo from cookie:', error);
                // JSON 파싱 오류 처리
            }
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            // 서버로부터 학생, 수강반 데이터 가져오기
            fetchExamData();
            fetchWorkbookData();
        }, 100); // 0.1초 뒤에 실행

        return () => clearTimeout(timer);
    }, [studentInfo,count]);



    

    //시험지 & 문제집 가져오기
    const fetchExamData = () =>{
        axios({
            method: "POST",
              url: `${back_ip_url}/fetchExamData`,
              data: {
                userId : studentInfo.user_id,
                },
              withCredentials : true,
          })
          .then(response => {
            const { examList, examQuestions, examStudents } = response.data;
            setExamList(examList);
            setFilteredExamList(examList)
            setExamQuestions(examQuestions);
            setExamStudents(examStudents);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }
    const fetchWorkbookData = () =>{
        axios({
            method: "POST",
              url: `${back_ip_url}/fetchWorkbookData`,
              data: {
                userId : studentInfo.user_id,
                },
              withCredentials : true,
          })
          .then(response => {
            const { workbook , workbookStudent } = response.data;
            setWorkbook(workbook);
            setWorkbookStudent(workbookStudent);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }
    //학생 문제
    const filteredExam = examStudents.filter(student => student.student_id === studentInfo.student_id);
    const mergedData = filteredExam.map(studentProblem => {
        const examInfo = examList.find(exam => exam.exam_id === studentProblem.exam_id);
        return {
            ...studentProblem,
            ...examInfo,
        };
    });

     //배정된 문제집 가져오기
     const filteredWorkbook = workbookStudent.filter(student => student.student_id === studentInfo.student_id)
     const mergedBookData = filteredWorkbook.map(studentProblem =>{
         const bookInfo = workbook.find(workbook => workbook.id === studentProblem.book_id)
         const examInfo = examList.find(exam => exam.exam_id === studentProblem.exam_id)
         return {
             ...studentProblem,
             ...bookInfo,
             ...examInfo,
         }
     })

    //날짜 T뒷부분 삭제
    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; // dateString이 null이나 undefined인 경우 빈 문자열 반환
        }
    
        const date = new Date(dateString); // Date 객체로 변환
    
        // UTC 시간을 9시간 더해서 한국 시간대로 변환
        const kstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
    
        const year = kstDate.getFullYear();
        const month = String(kstDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
        const day = String(kstDate.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`; // 'YYYY-MM-DD' 형식으로 반환
    };
    const logcheck = () =>{
        console.log(studentInfo)
        console.log("studentInfo.user_id",studentInfo.user_id)
        console.log('mergedData',mergedData)
    }
    const LogCheck_workbook = () =>{
        
    }

    //채점 시험지 불러오기
    const SelectedMergedData = mergedData[selectedRow]
    let scoreQuestions = [];
    let itemIds = [];
    let itemIdsString = "";
    let itemIdsString2 = "";
    let score = "0"
    let condition = 0
    const [markAnswer,setMarkAnswer] = useState()
    const [colorO, setColorO] = useState(
        Array(markAnswer?.length || 0).fill('')
    );
    const [colorX,setColorX] = useState(
        Array(markAnswer?.length || 0).fill('')
    )
    let newColors = [...colorO];
    let newColors2 = [...colorX];

    const [wrongItemId,setWrongItemId] = useState(1)
    if (mergedData && mergedData[selectedRow]) {
        scoreQuestions = examQuestions.filter(question => question.exam_id === mergedData[selectedRow].exam_id);
        itemIds = scoreQuestions.map(question => question.item_id);
        itemIdsString = itemIds.map(id => `'${id}'`).join(',');
        score = SelectedMergedData.items -wrongItemId.length
        if(SelectedMergedData.score == null){
            condition = 1
        }
    }
    if (mergedBookData && mergedBookData[selectedRow2]) {
        scoreQuestions = examQuestions.filter(question => question.exam_id === mergedBookData[selectedRow2].exam_id);
        itemIds = scoreQuestions.map(question => question.item_id);
        itemIdsString2 = itemIds.map(id => `'${id}'`).join(',');
    }

    //시험지 채점
    const [modalIsOpen1,setModalIsOpen1] = useState(false)
    const [modalIsOpen2,setModalIsOpen2] = useState(false)


    const handleMark = (index, isCorrect) => {
        newColors[index] = isCorrect ? 'lightgreen' : 'black';
        newColors2[index] = isCorrect ? 'black' : 'orange';

        setColorO(newColors);
        setColorX(newColors2);


        const newOrangeIndices = newColors
        .map((color, idx) => (color === 'black' ? idx+1 : -1))
        .filter((idx) => idx !== -1);
        setWrongItemId(newOrangeIndices);
    };

    const ScoreExam = () =>{
        loadingMark()
    }
    const ScoreExamBook = () =>{
        loadingMark2()
    }

    const loadingMark = () =>{
        axios({
            method: "POST",
              url: `${back_ip_url}/loadingMark`,
              data: {
                examId : mergedData[selectedRow].exam_id,
                userId : studentInfo.user_id
                },
              withCredentials : true,
          })
          .then(response => {
            setMarkAnswer(response.data)
            setModalIsOpen1(true);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }
    const loadingMark2 = () =>{
        axios({
            method: "POST",
              url: `${back_ip_url}/loadingMark`,
              data: {
                examId : mergedBookData[selectedRow2].exam_id,
                userId : studentInfo.user_id
                },
              withCredentials : true,
          })
          .then(response => {
            setMarkAnswer(response.data)
            setModalIsOpen2(true);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }
    //오답문제 저장
    const saveScore =()=>{
        axios({
            method: "POST",
                url: `${back_ip_url}/saveScore`,
                data : {
                student : studentInfo.student_id,
                exam : SelectedMergedData.exam_id,
                wrong_id : wrongItemId,
                score : wrongItemId.length,
                userId : studentInfo.user_id
            },
                withCredentials : true,
            })
            .then(response => {
            if (response.status === 200) {
                alert(response.data)
                closeModal1()
                setCount(count+1)
            }
            })
            .catch(error => {
                console.error('Error fetching tree data:', error);
            });
    }
    const saveScore2 =()=>{
        axios({
            method: "POST",
                url: `${back_ip_url}/saveScore2`,
                data : {
                student : studentInfo.student_id,
                exam : mergedBookData[selectedRow2].exam_id,
                book : mergedBookData[selectedRow2].book_id,
                wrong_id : wrongItemId,
                score : wrongItemId.length,
                userId : studentInfo.user_id
            },
                withCredentials : true,
            })
            .then(response => {
            if (response.status === 200) {
                alert(response.data)
                closeModal2()
                setCount(count+1)
            }
            })
            .catch(error => {
                console.error('Error saving score for book(exam):', error);
            });
    }
    function exam_switchBtn3(){
        document.getElementById('exam_designated').classList.replace('block', 'none');
        document.getElementById('book_designated').classList.replace('none', 'block');
        document.getElementById('exam_designated3').classList.replace('block', 'none');
        document.getElementById('book_designated3').classList.replace('none', 'block');
        document.getElementById('seeBook').classList.replace('block', 'none');
        document.getElementById('seeExam').classList.replace('none', 'block');
    }
    function exam_switchBtn4(){
        document.getElementById('exam_designated').classList.replace('none', 'block');
        document.getElementById('book_designated').classList.replace('block', 'none');
        document.getElementById('exam_designated3').classList.replace('none', 'block');
        document.getElementById('book_designated3').classList.replace('block', 'none');
        document.getElementById('seeExam').classList.replace('block', 'none');
        document.getElementById('seeBook').classList.replace('none', 'block');
    }

return(
    <div>
        <Modal className="Modal_Score2"isOpen={modalIsOpen1} onRequestClose={() => closeModal1()}>
            <div className="Modal_head">
                <h2>채점</h2>
                <button className="xMark" onClick={() => closeModal1()}><IoMdClose /></button>
            </div>
            <div style={{display:'flex',flexDirection:"column",alignItems:'center'}}>
                {selectedRow !== undefined ? (
                    <div className="scoreInfo">
                        <p>시험지명: {mergedData[selectedRow].exam_name}</p>
                        <p>학생명: {studentInfo.student_name}</p>
                    </div>
                ):(<p>d</p>)}
                {markAnswer && markAnswer.length > 0 ? (
                    markAnswer.map((markAnswer, index) => (
                        <div key={index} className="mark-answer-item" >
                            <p style={{width:'110px'}}>문제 {index + 1}번 답:</p>
                            <p style={{width:'50px'}}>{markAnswer.answer_text}</p>
                            {/* O/X 체크 버튼 */}
                            <button  className="oMark" onClick={() => handleMark(index, true)}><FaRegCheckCircle style={{ color: colorO[index] }}/></button>
                            <button  className="xMark2" onClick={() => handleMark(index, false)}><FaRegTimesCircle style={{ color: colorX[index] }}/></button>
                        </div>
                    ))
                ) : (
                    <p>No mark answers available.</p>
                )}
                <button className="buttonB btnColorA confirm_btn" style={{margin:'10px', width : '100%'}} onClick={saveScore}>저장</button>
            </div>
        </Modal>
        <Modal className="Modal_Score2" isOpen={modalIsOpen2} onRequestClose={() => closeModal2()}>
            <div className="Modal_head">
                <h2>채점</h2>
                <button className="xMark" onClick={() => closeModal2()}><IoMdClose /></button>
            </div>
            <div>
                {selectedRow2 !== undefined ? (
                    <div className="scoreInfo">
                        <p>문제집 이름: {mergedBookData[selectedRow2].name}</p>
                        <p>시험지명: {mergedBookData[selectedRow2].exam_name}</p>
                        <p>학생명: {studentInfo.student_name}</p>
                    </div>
                ):(<p>d</p>)}
                {markAnswer && markAnswer.length > 0 ? (
                    markAnswer.map((markAnswer, index) => (
                        <div key={index} className="mark-answer-item">
                            <p>문제 {index + 1}번 답: {markAnswer.answer_text}</p>
                            {/* O/X 체크 버튼 */}
                            <button className="oMark" onClick={() => handleMark(index, true)}><FaRegCheckCircle style={{ color: colorO[index] }}/></button>
                            <button className="xMark2" onClick={() => handleMark(index, false)}><FaRegTimesCircle style={{ color: colorX[index] }}/></button>
                        </div>
                    ))
                ) : (
                    <p>No mark answers available.</p>
                )}
                <button className="buttonB btnColorA confirm_btn" style={{margin:'10px' ,}}onClick={saveScore2}>저장</button>
                <button onClick={LogCheck_workbook}>LOG 확인</button>
            </div>
        </Modal>
        <div className='marking_main'>
            <div>
                <button onClick={logcheck}>logcheck</button>
                <button onClick={ScoreExam} id="exam_designated3" className='buttonA btnColorA confirm_btn block' >채점하기</button>
                <button onClick={ScoreExamBook} id="book_designated3"className='buttonA btnColorA confirm_btn none' >채점하기</button>
                <button onClick={exam_switchBtn3} id="seeBook" className="buttonA btnColorA block">문제집 보기</button>
                <button onClick={exam_switchBtn4} id="seeExam" className="buttonA btnColorA none">시험지 보기</button>
            </div>
            <div className="divtable exam_designated block" id="exam_designated">
                <table>
                    <thead>
                        <th class="th-name">시험지 이름</th>
                        <th class="th-count">문항 수</th>
                        <th class="th-date">생성일</th>
                        <th class="th-author">출제자</th>
                        <th>정답/오답</th>
                    </thead>
                    <tbody>
                        {mergedData.map((data, index) => (
                            <tr key={index} style={{ backgroundColor: selectedRow === index 
                                ? 'lightblue' : 'white' }}  onClick={() => handleRowClick(index)}>
                                <td>{data.exam_name}</td>
                                <td>{data.items}</td>
                                <td>{formatDate(data.date)}</td>
                                <td>{data.teacher_name}</td>
                                <td>{data.score !== null ? (data.items - data.score) + ' / ' + data.score : '미채점' }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="divtable book_designated none" id="book_designated">
                    <table>
                        <thead>
                            <th class="th-name">문제집 이름</th>
                            <th>시험지 이름</th>
                            <th class="th-date">생성일</th>
                            <th>정답/오답</th>
                        </thead>
                        <tbody>
                            {mergedBookData.map((data, index) => (
                                    <tr key={index} style={{ backgroundColor: selectedRow2 === index 
                                        ? 'lightblue' : 'white' }}  onClick={() => handleRowClick2(index)}>
                                        <td>{data.name}</td>
                                        <td>{data.exam_name}</td>
                                        <td>{formatDate(data.date)}</td>
                                        <td>{data.score !== null ? (data.items - data.score) + ' / ' + data.score : '미채점' }</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
        </div>
    </div>
)
}