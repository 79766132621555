import Header from "../Header/Header"
import './TestCreation.css';
import UserInfo from '../UserInfo/UserInfo.jsx'
import axios from 'axios';
import { useState , useEffect } from 'react';
import Modal from 'react-modal'
import TreeViewer2 from "../TreeView/TreeView2.jsx";
import TestInfo from "../TreeView/TestInfo.jsx";
import { TiDocumentAdd,TiDocumentDelete  } from "react-icons/ti";
import { LuFileSearch , LuFileMinus2} from "react-icons/lu";
import { GrScorecard } from "react-icons/gr";
import { IoDocumentsOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { FaRegTimesCircle ,FaRegCheckCircle,FaAngleRight,FaAngleLeft,FaSearch} from "react-icons/fa";
import { IoMdPeople } from "react-icons/io";




export default function TestCreation(){
    const back_ip_url = process.env.REACT_APP_SERVER_URL
    const userInfo = UserInfo().userInfo;
    const isLogin = UserInfo().isLogin;
    const [studentData,setStudentData] = useState()
    const [selectedRow, setSelectedRow] = useState();
    const [selectedRow2, setSelectedRow2] = useState();
    const [selectedRow3, setSelectedRow3] = useState();
    const [selectedRow4, setSelectedRow4] = useState();
    const [selectedRow5, setSelectedRow5] = useState();
    const [selectedRow6, setSelectedRow6] = useState();
    const [selectedStu,setSelectedStu] = useState([]);
    const [selectedClass,setSelectedClass] = useState([]);
    const [courseData,setCourseData] = useState()
    const [studentCourseData,setStudentCourseData] = useState();
    const [examList, setExamList] = useState([]);
    const [filteredExamList,setFilteredExamList] = useState([]);
    const [filteredbookList,setFilteredbookList] = useState([]);
    const [examQuestions, setExamQuestions] = useState([]);
    const [examStudents, setExamStudents] = useState([]);
    const [workbook,setWorkbook] = useState([])
    const [workbookStudent,setWorkbookStudent] = useState([])
    const [selectedExam,setSelectedExam] = useState([])
    const [selectedExam_designated,setSelectedExam_designated] = useState([])
    const [selectedBookId,setSelectedBookId] = useState([])
    const [selectedBookId_designated,setSelectedBookId_designated] = useState([])
    const [count,setCount] = useState(0);
    const [treeData, setTreeData] = useState(null);
    const [treeDataOrigin, setTreeDataOrigin] = useState(null);


    const [chosenItem2, setChosenItem2] = useState([]);

    //현재 날짜
    const [currentDate, setCurrentDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    useEffect(() => {
        const today = new Date();
        const sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(today.getMonth() - 6);
        
        const formattedToday = today.toISOString().split('T')[0];
        const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split('T')[0];

        document.getElementById('start-date').value = formattedSixMonthsAgo;
        document.getElementById('end-date').value = formattedToday;
        
        const formattedDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD' 형식으로 포맷팅
        setCurrentDate(formattedDate);
        setStartDate(formattedSixMonthsAgo)
        setEndDate(formattedToday)
    }, []);
    
    //배정된 시험지 가져오기
    const filteredExam = examStudents.filter(item => selectedStu.includes(item.student_id));
    const mergedData = filteredExam.map(studentProblem => {
        const examInfo = filteredExamList.find(exam => exam.exam_id === studentProblem.exam_id && exam.date >= startDate && exam.date  <= endDate);
        const studentInfo = studentData.find(student => student.student_id === studentProblem.student_id);
        if (!examInfo) return null;
        return {
            ...studentProblem,
            ...examInfo,
            student: studentInfo
        };
    }).filter(data => data !== null);

    //배정된 문제집 가져오기
    const filteredWorkbook = workbookStudent.filter(item=> selectedStu.includes(item.student_id))
    const mergedBookData = filteredWorkbook.map(studentProblem =>{
        const bookInfo = filteredbookList.find(workbook => workbook.id === studentProblem.book_id)
        const studentInfo = studentData.find(student => student.student_id === studentProblem.student_id);
        const examInfo = examList.find(exam => exam.exam_id === studentProblem.exam_id)
        if (!bookInfo) return null;
        return {
            ...studentProblem,
            ...bookInfo,
            ...examInfo,
            student : studentInfo
        }
    }).filter(data => data !== null);

    //채점 시험지 불러오기
    const SelectedMergedData = selectedExam_designated
    let scoreQuestions = [];
    let itemIds = [];
    let itemIdsString = "";
    let itemIdsString2 = "";
    let score = "0"
    let condition = 0
    const [markAnswer,setMarkAnswer] = useState()

    const [colorO, setColorO] = useState(
        Array(markAnswer?.length || 0).fill('')
    );
    const [colorX,setColorX] = useState(
        Array(markAnswer?.length || 0).fill('')
    )
    let newColors = [...colorO];
    let newColors2 = [...colorX];

    const [wrongItemId,setWrongItemId] = useState(1)
    if (mergedData && selectedExam_designated) {
        scoreQuestions = examQuestions.filter(question => question.exam_id === selectedExam_designated.exam_id);
        itemIds = scoreQuestions.map(question => question.item_id);
        itemIdsString = itemIds.map(id => `'${id}'`).join(',');
        score = SelectedMergedData.items -wrongItemId.length
        if(SelectedMergedData.score == null){
            condition = 1
        }
    }
    if (mergedBookData && selectedBookId_designated) {
        scoreQuestions = examQuestions.filter(question => question.exam_id === selectedBookId_designated.exam_id);
        itemIds = scoreQuestions.map(question => question.item_id);
        itemIdsString2 = itemIds.map(id => `'${id}'`).join(',');
    }
    const fetchTreeData2 = () => {
        if(userInfo.sharing == 1){
            axios({
                method: "POST",
                    url: `${back_ip_url}/getTreeData2`,
                    data: {
                        userId : userInfo.id,
                        sharing : userInfo.sharing,
                        sharing2 : userInfo.sharing2
                    },
                    withCredentials : true,
                })
                .then(response => {
                    setTreeData(response.data);
                    setTreeDataOrigin(response.data)
                })
                .catch(error => {
                    console.error('Error fetching tree data:', error);
                });
        }else{
            axios({
                method: "POST",
                    url: `${back_ip_url}/getTreeData`,
                    data: {userId : userInfo.id},
                    withCredentials : true,
                })
                .then(response => {
                    setTreeData(response.data);
                    setTreeDataOrigin(response.data)
                })
                .catch(error => {
                    console.error('Error fetching tree data:', error);
                });
        }
        
    };
    //채점 함수
    const loadingMark = () =>{
        axios({
            method: "POST",
              url: `${back_ip_url}/loadingMark`,
              data: {
                examId : selectedExam_designated.exam_id,
                userId : userInfo.id
                },
              withCredentials : true,
          })
          .then(response => {
            setMarkAnswer(response.data)
            setModalIsOpen2(true);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }
    const loadingMark2 = () =>{
        axios({
            method: "POST",
              url: `${back_ip_url}/loadingMark`,
              data: {
                examId : selectedBookId_designated.exam_id,
                userId : userInfo.id
                },
              withCredentials : true,
          })
          .then(response => {
            setMarkAnswer(response.data)
            setModalIsOpen4(true);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }
    const handleMark = (index, isCorrect) => {
        newColors[index] = isCorrect ? 'lightgreen' : 'black';
        newColors2[index] = isCorrect ? 'black' : 'orange';

        setColorO(newColors);
        setColorX(newColors2);


        const newOrangeIndices = newColors
        .map((color, idx) => (color === 'black' ? idx+1 : -1))
        .filter((idx) => idx !== -1);
        setWrongItemId(newOrangeIndices);
    };
    //날짜 T뒷부분 삭제
    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; // dateString이 null이나 undefined인 경우 빈 문자열 반환
        }
    
        const date = new Date(dateString); // Date 객체로 변환
    
        // UTC 시간을 9시간 더해서 한국 시간대로 변환
        const kstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
    
        const year = kstDate.getFullYear();
        const month = String(kstDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
        const day = String(kstDate.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`; // 'YYYY-MM-DD' 형식으로 반환
    };
    //학교 학년
    const school_grade = [
        { id: 15, name: "=============" },
        { id: 1, name: "초등학교 1학년" },
        { id: 2, name: "초등학교 2학년" },
        { id: 3, name: "초등학교 3학년" },
        { id: 4, name: "초등학교 4학년" },
        { id: 5, name: "초등학교 5학년" },
        { id: 6, name: "초등학교 6학년" },
        { id: 13, name: "=============" },
        { id: 7, name: "중학교 1학년" },
        { id: 8, name: "중학교 2학년" },
        { id: 9, name: "중학교 3학년" },
        { id: 14, name: "=============" },
        { id: 10, name: "고등학교 1학년" },
        { id: 11, name: "고등학교 2학년" },
        { id: 12, name: "고등학교 3학년" }
    ];
        // 학년 ID를 이름으로 변환하는 함수
        const getGradeName = (gradeId) => {
            const grade = school_grade.find(g => g.id == gradeId);
            return grade ? grade.name : '학년 정보 없음';
        };
    
    //날짜 검색
    const [filteredExamListStudent,setFilteredExamListStudent] =useState([])

    const dateSearch = () =>{
        const startDate = new Date(document.getElementById('start-date').value);
        const endDate = new Date(document.getElementById('end-date').value);

        setFilteredExamList(examList.filter(exam => {
            const examDate = new Date(exam.date);
            return examDate >= startDate && examDate <= endDate;
        }))
        setFilteredbookList(workbook.filter(exam => {
            const examDate = new Date(exam.date);
            return examDate >= startDate && examDate <= endDate;
        }))
    }
    //db 가져오기
    const fetchStudentData = () =>{
        axios({
            method: "GET",
              url: `${back_ip_url}/fetchStudentData`,
              params: userInfo,
              withCredentials : true,
          })
          .then(response => {
              setStudentData(response.data);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
        }
    
        const fetchCourseData = () =>{
            axios({
                method: "GET",
                  url: `${back_ip_url}/fetchCourseData`,
                  params: userInfo,
                  withCredentials : true,
              })
              .then(response => {
                    setCourseData(response.data);
              })
              .catch(error => {
                  console.error('Error fetching tree data:', error);
              });
            }
        
        const fetchStudentCourseData = () =>{
            axios({
                method: "GET",
                  url: `${back_ip_url}/fetchStudentCourseData`,
                  params: userInfo,
                  withCredentials : true,
              })
              .then(response => {
                    setStudentCourseData(response.data);
              })
              .catch(error => {
                  console.error('Error fetching tree data:', error);
              });
        }
        const fetchExamData = () =>{
            axios({
                method: "POST",
                  url: `${back_ip_url}/fetchExamData`,
                  data: {
                    userId : userInfo.id
                    },
                  withCredentials : true,
              })
              .then(response => {
                const { examList, examQuestions, examStudents } = response.data;
                setExamList(examList);
                setFilteredExamList(examList)
                setExamQuestions(examQuestions);
                setExamStudents(examStudents);
              })
              .catch(error => {
                  console.error('Error fetching tree data:', error);
              });
        }
        const fetchWorkbookData = () =>{
            axios({
                method: "POST",
                  url: `${back_ip_url}/fetchWorkbookData`,
                  data: {
                    userId : userInfo.id
                    },
                  withCredentials : true,
              })
              .then(response => {
                const { workbook , workbookStudent } = response.data;
                setWorkbook(workbook);
                setWorkbookStudent(workbookStudent);
                setFilteredbookList(workbook)
              })
              .catch(error => {
                  console.error('Error fetching tree data:', error);
              });
        }
    
    
        useEffect(() => {
            if (isLogin) {  // 로그인 상태인지 확인
            const timer = setTimeout(() => {
                // 서버로부터 학생, 수강반 데이터 가져오기
                fetchStudentData();
                fetchCourseData();
                fetchStudentCourseData();
                fetchExamData();
                fetchWorkbookData();
                fetchTreeData2();
            }, 100); // 0.1초 뒤에 실행
    
            return () => clearTimeout(timer);}
        }, [userInfo,count]);
    
        const handleRowClick = (index) => {
            setSelectedRow(index);
        }
        const handleRowClick2 = (index) => {
            setSelectedRow2(index);
            setSelectedExam(currentItems[index])
        }
        const handleRowClick3 = (index) => {
            setSelectedRow3(index);
            setSelectedExam_designated(currentItems_designated_exam[index])
        }
        const handleRowClick4 = (index) => {
            setSelectedRow4(index);
        }
        const handleRowClick5 = (index) =>{
            setSelectedRow5(index)
            setSelectedBookId(currentItems_All_Workbook[index])
        }
        const handleRowClick6 = (index) =>{
            setSelectedRow6(index)
            setSelectedBookId_designated(currentItems_designated_Workbook[index])
        }
        const handleCheckboxChange = (index, isChecked) => {
            if (isChecked) {
                setSelectedStu(prevIds => [...prevIds, index]);
            } else {
                setSelectedStu(prevIds => prevIds.filter(id => id !== index));
            }
            setSelectedRow3()
            console.log(filteredExam)
            console.log(selectedStu)
        }
        const handleCheckboxChange2 = (index, isChecked) => {
            if (isChecked) {
                setSelectedClass(prevIds => [...prevIds, index]);
            } else {
                setSelectedClass(prevIds => prevIds.filter(id => id !== index));
            }
        }

        ////////////

        
    //로그체크 
        const LogCheck = () => {
            console.log("selectedStu",selectedStu)
            console.log("selectedExam.exam_id",selectedExam.exam_id)
            console.log("SelectedMergedData",SelectedMergedData)
            console.log("mergedData",mergedData)
            console.log("filteredExam",filteredExam)
            console.log("endDate",endDate)
            console.log("endDate",formatDate(endDate))
            console.log(currentItems)
            console.log("chosenItem2",chosenItem2)

        }
        const LogCheck2 = () =>{
            console.log("newColors",newColors)
            console.log("WrongItemId",wrongItemId)
            console.log("examID",SelectedMergedData.exam_id)
            console.log("studentId",SelectedMergedData.student.student_id)
            console.log("score",score)
            console.log("wrongItemId.length",wrongItemId.length)
            console.log("mergedData.items",SelectedMergedData.items)
            console.log("itemIdsString",itemIdsString)
        }
        const LogCheck3 = () =>{
            console.log("selectedBookId",selectedBookId)
            console.log("filteredWorkbook",filteredWorkbook)
            console.log("mergedBookData",mergedBookData)
        }
        const LogCheck4 = () =>{
            console.log("itemIdsString2",itemIdsString2)
            console.log("mergedBookData",mergedBookData)
            console.log("mergedBookData[selectedRow6].student.student_id",mergedBookData[selectedRow6].student.student_id)
            console.log("mergedBookData[selectedRow6].exam_id",mergedBookData[selectedRow6].exam_id)
            console.log("wrongItemId",wrongItemId)
            console.log("wrongItemId.length",wrongItemId.length)
        }
        const [activeTab, setActiveTab] = useState('student'); // 기본 상태는 'student'로 설정
        function switchBtn(){
            document.getElementById('listHeader_Stu').classList.replace('block', 'none');
            document.getElementById('listHeader_Class').classList.replace('none', 'block');
            document.getElementById('button_Stu').classList.replace('block', 'none');
            document.getElementById('button_Class').classList.replace('none', 'block');
            setSelectedClass([])
            setSelectedStu([])
            setActiveTab('course')
            setSelectedRow3()
            setSelectedRow6()

        }
        function switchBtn2(){
            document.getElementById('listHeader_Stu').classList.replace('none', 'block');
            document.getElementById('listHeader_Class').classList.replace('block', 'none');
            document.getElementById('button_Stu').classList.replace('none', 'block');
            document.getElementById('button_Class').classList.replace('block', 'none');
            setSelectedClass([])
            setSelectedStu([])

            setActiveTab('student')
        }    
        function exam_switchBtn(){
            document.getElementById('exam_all').classList.replace('block', 'none');
            document.getElementById('exam_designated').classList.replace('none', 'block');
            document.getElementById('exam_all2').classList.replace('block', 'none');
            document.getElementById('exam_designated2').classList.replace('none', 'block');
            document.getElementById('exam_all3').classList.replace('block', 'none');
            document.getElementById('exam_designated3').classList.replace('none', 'block');
        }
        function exam_switchBtn2(){
            document.getElementById('exam_designated').classList.replace('block', 'none');
            document.getElementById('exam_all').classList.replace('none', 'block');
            document.getElementById('exam_designated2').classList.replace('block', 'none');
            document.getElementById('exam_all2').classList.replace('none', 'block');
            document.getElementById('exam_designated3').classList.replace('block', 'none');
            document.getElementById('exam_all3').classList.replace('none', 'block');
        }
        function exam_switchBtn3(){
            document.getElementById('exam_designated').classList.replace('block', 'none');
            document.getElementById('exam_designated2').classList.replace('block', 'none');
            document.getElementById('exam_designated3').classList.replace('block', 'none');
            document.getElementById('exam_all').classList.replace('block', 'none');
            document.getElementById('exam_all2').classList.replace('block', 'none');
            document.getElementById('exam_all3').classList.replace('block', 'none');
            document.getElementById('book_all').classList.replace('none', 'block');
            document.getElementById('book_all2').classList.replace('none', 'block');
            document.getElementById('book_all3').classList.replace('none', 'block');
            document.getElementById('book_designated').classList.replace('block', 'none');
            document.getElementById('book_designated2').classList.replace('block', 'none');
            document.getElementById('book_designated3').classList.replace('block', 'none');
            document.getElementById('seeBook').classList.replace('block', 'none');
            document.getElementById('seeExam').classList.replace('none', 'block');
            setSelectedRow3()
            setSelectedRow6()

        }
        function exam_switchBtn4(){
            document.getElementById('exam_designated').classList.replace('block', 'none');
            document.getElementById('exam_designated2').classList.replace('block', 'none');
            document.getElementById('exam_designated3').classList.replace('block', 'none');
            document.getElementById('exam_all').classList.replace('none', 'block');
            document.getElementById('exam_all2').classList.replace('none', 'block');
            document.getElementById('exam_all3').classList.replace('none', 'block');
            document.getElementById('book_all').classList.replace('block', 'none');
            document.getElementById('book_all2').classList.replace('block', 'none');
            document.getElementById('book_all3').classList.replace('block', 'none');
            document.getElementById('book_designated').classList.replace('block', 'none');
            document.getElementById('book_designated2').classList.replace('block', 'none');
            document.getElementById('book_designated3').classList.replace('block', 'none');
            document.getElementById('seeExam').classList.replace('block', 'none');
            document.getElementById('seeBook').classList.replace('none', 'block');
            setSelectedRow3()
            setSelectedRow6()
        }
        function exam_switchBtn5(){
            document.getElementById('exam_designated').classList.replace('block', 'none');
            document.getElementById('exam_designated2').classList.replace('block', 'none');
            document.getElementById('exam_designated3').classList.replace('block', 'none');
            document.getElementById('exam_all').classList.replace('block', 'none');
            document.getElementById('exam_all2').classList.replace('block', 'none');
            document.getElementById('exam_all3').classList.replace('block', 'none');
            document.getElementById('book_all').classList.replace('block', 'none');
            document.getElementById('book_all2').classList.replace('block', 'none');
            document.getElementById('book_all3').classList.replace('block', 'none');
            document.getElementById('book_designated').classList.replace('none', 'block');
            document.getElementById('book_designated2').classList.replace('none', 'block');
            document.getElementById('book_designated3').classList.replace('none', 'block');
        }
        function exam_switchBtn6(){
            document.getElementById('exam_designated').classList.replace('block', 'none');
            document.getElementById('exam_designated2').classList.replace('block', 'none');
            document.getElementById('exam_designated3').classList.replace('block', 'none');
            document.getElementById('exam_all').classList.replace('block', 'none');
            document.getElementById('exam_all2').classList.replace('block', 'none');
            document.getElementById('exam_all3').classList.replace('block', 'none');
            document.getElementById('book_all').classList.replace('none', 'block');
            document.getElementById('book_all2').classList.replace('none', 'block');
            document.getElementById('book_all3').classList.replace('none', 'block');
            document.getElementById('book_designated').classList.replace('block', 'none')
            document.getElementById('book_designated2').classList.replace('block', 'none');
            document.getElementById('book_designated3').classList.replace('block', 'none');
        }
        const [modalIsOpen,setModalIsOpen] = useState(false)
        const [modalIsOpenTest,setModalIsOpenTest] = useState(false)

        const [modalIsOpen_checkStudent,setModalIsOpen_checkStudent] = useState(false)
        const [courseIndex,setCourseIndex]= useState()
        const closeModal = () =>{
            setModalIsOpen(false)
            setModalIsOpen2(false)
            setModalIsOpenTest(false)
            setCount(count+1)
        }
        const closeModal2 = () =>{
            setModalIsOpen2(false)
            setColorX([])
            setColorO([])
        }
        const closeModal4 = () =>{
            setModalIsOpen4(false)
            setColorX([])
            setColorO([])
        }
        const openCreateExam = () =>{
            setModalIsOpen(true)
        }
        const openCreateExamTest = () =>{
            setModalIsOpenTest(true)
        }

        //모달이동
        const nextModal = () =>{
            setModalIsOpen(false)
            setModalIsOpenTest(true)
        }
        const prevModal = () =>{
            setModalIsOpenTest(false)
            setModalIsOpen(true)
        }
        //시험지 조회
        const [isPopoverOpen1,setIsPopoverOpen1] = useState(false)
        const [isPopoverOpen2,setIsPopoverOpen2] = useState(false)
        const [isPopoverOpen3,setIsPopoverOpen3] = useState(false)

        const openPopover1 = () =>{
            setIsPopoverOpen1(!isPopoverOpen1)
        }
        const openPopover2 = () =>{
            setIsPopoverOpen2(!isPopoverOpen2)
        }
        const openPopover3 = () =>{
            setIsPopoverOpen3(!isPopoverOpen3)
        }
        const pythonOpenExam = (modeNum,exam_id) =>{
            var url = new URL('http://localhost:8000/manage_question/makeHwpExam');
            var params = { 
                    mode: modeNum,
                    user_name: userInfo.id,
                    exam_id: exam_id,
                    image_url : userInfo.pictureURL
            };
            fetch(url,{
                method : 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(params)
            })
            .then(response => {
                // 응답 상태 코드 확인
                if (response.status === 200) {
                    return response.text();  // 성공 시 텍스트를 반환
                } else {
                    throw new Error(`Request failed with status ${response.status}`);
                }
            })
            .then(data => alert(data))
            .catch(error => {console.error('Error:', error)
                alert('오류발생 : 파이썬 실행 상태를 확인해주세요')
            });
        }
        //시험지 배정
        const examToStudent = () =>{
            axios({
                method: "POST",
                  url: `${back_ip_url}/examToStudent`,
                  data : {
                    student_id : selectedStu,
                    exam_id: selectedExam.exam_id,
                    userId : userInfo.id
                },
                  withCredentials : true,
              })
              .then(response => {
                if (response.status === 200) {
                    alert(response.data)
                    setCount(count+1)
                }
              })
              .catch(error => {
                  console.error('Error exam to student:', error);
              });
        }
        //시험지 삭제 
        const DeleteExam = () =>{
            const result = window.confirm("선택된 시험지를 삭제하시겠습니까? (배정된 시험지도 삭제됨)");
            if (result) {
                    axios({
                        method: "POST",
                        url: `${back_ip_url}/deleteExam`,
                        data : {
                        student_id : selectedStu,
                        exam_id: selectedExam.exam_id,
                        userId : userInfo.id
                    },
                        withCredentials : true,
                    })
                    .then(response => {
                    if (response.status === 200) {
                        alert(response.data)
                        setSelectedRow2(null)
                        setCount(count+1)
                    }
                    })
                    .catch(error => {
                        console.error('Error delete exam:', error);
                    });
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }
        }
        const DeleteExamStudent = () =>{
            const result = window.confirm("선택된 시험지를 배정 취소하시겠습니까? ");
            if (result) {
                    axios({
                        method: "POST",
                        url: `${back_ip_url}/deleteExamStudent`,
                        data : {
                        student_id : SelectedMergedData.student_id,
                        exam_id: SelectedMergedData.exam_id,
                        userId : userInfo.id
                    },
                        withCredentials : true,
                    })
                    .then(response => {
                    if (response.status === 200) {
                        alert("취소 완료")
                        setCount(count+1)
                        setSelectedRow3()
                    }
                    })
                    .catch(error => {
                        console.error('Error fetching tree data:', error);
                    });
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }
        }
        const DeleteBookStudent = () =>{
            const result = window.confirm("선택된 문제집를 배정 취소하시겠습니까?");
            if (result) {
                axios({
                    method: "POST",
                    url: `${back_ip_url}/deleteBookStudent`,
                    data : {
                    student_id : selectedBookId_designated.student_id,
                    book_id: selectedBookId_designated.book_id,
                    userId : userInfo.id
                },
                    withCredentials : true,
                })
                .then(response => {
                if (response.status === 200) {
                    alert("취소 완료")
                    setCount(count+1)
                    setSelectedRow6()
                }
                })
                .catch(error => {
                    console.error('Error fetching tree data:', error);
                });
        } else {
            // 사용자가 취소를 선택한 경우
            console.log("사용자가 취소를 선택했습니다.");
        }
        }
        //오답문제 재출제
        const makeRetest = () =>{
            const result = window.confirm("오답문제를 재출제 하시겠습니까? ");
            if (result) {
                axios({
                    method: "POST",
                      url: `${back_ip_url}/makeRetest`,
                      data : {
                        selectedExam : SelectedMergedData,
                        date : currentDate,
                        userId : userInfo.id
                    },
                      withCredentials : true,
                  })
                  .then(response => {
                    if (response.status === 200) {
                        alert(response.data)
                        setCount(count+1)
                    }
                  })
                  .catch(error => {
                      console.error('Error fetching tree data:', error);
                  });
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }

        }
        //유사문항 재출제
        const makeRetest2 = () =>{
            const result = window.confirm("오답문제(유사문항)를 재출제 하시겠습니까? ");
            if (result) {
            axios({
                method: "POST",
                  url: `${back_ip_url}/makeRetest2`,
                  data : {
                    selectedExam : SelectedMergedData,
                    date : currentDate,
                    userId : userInfo.id

                },
                  withCredentials : true,
              })
              .then(response => {
                if (response.status === 200) {
                    alert(response.data)
                    setCount(count+1)
                }
              })
              .catch(error => {
                  console.error('Error fetching tree data:', error);
              });
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }

        }
        //시험지 채점
        const [modalIsOpen2,setModalIsOpen2] = useState(false)
        const [modalIsOpen4,setModalIsOpen4] = useState(false)

        
        const ScoreExam = () =>{
            loadingMark()
        }
        const ScoreExamBook = () =>{
            loadingMark2()
        }
        //오답문제 저장
        const saveScore =()=>{
            axios({
                method: "POST",
                  url: `${back_ip_url}/saveScore`,
                  data : {
                    student : SelectedMergedData.student.student_id,
                    exam : SelectedMergedData.exam_id,
                    wrong_id : wrongItemId,
                    score : wrongItemId.length,
                    userId : userInfo.id
                },
                  withCredentials : true,
              })
              .then(response => {
                if (response.status === 200) {
                    alert(response.data)
                    closeModal2()
                    setCount(count+1)
                }
              })
              .catch(error => {
                  console.error('Error fetching tree data:', error);
              });
        }
        const saveScore2 =()=>{
            axios({
                method: "POST",
                  url: `${back_ip_url}/saveScore2`,
                  data : {
                    student : selectedBookId_designated.student.student_id,
                    exam : selectedBookId_designated.exam_id,
                    book : selectedBookId_designated.book_id,
                    wrong_id : wrongItemId,
                    score : wrongItemId.length,
                    userId : userInfo.id
                },
                  withCredentials : true,
              })
              .then(response => {
                if (response.status === 200) {
                    alert(response.data)
                    closeModal4()
                    setCount(count+1)
                    setSelectedRow6()
                }
              })
              .catch(error => {
                  console.error('Error saving score for book(exam):', error);
              });
        }
        //문제집 만들기
        const [modalIsOpen3,setModalIsOpen3] = useState(false)
        const [bookName,setBookName] = useState("")

        const closeModal3 = () =>{
            setModalIsOpen3(false)
            setSelectBook([])
            setBookName("")
        }
        const openBookModal = () =>{
            setModalIsOpen3(true)
        }
        const makeBook = () =>{
            console.log(selectBook)
            console.log(bookName)
            axios({
                method: "POST",
                  url: `${back_ip_url}/makeBook`,
                  data : {
                    exam_id : selectBook,
                    name : bookName,
                    date : currentDate,
                    teacher : userInfo.name,
                    userId : userInfo.id
                },
                  withCredentials : true,
              })
              .then(response => {
                if (response.status === 200) {
                    alert(response.data)
                    setCount(count+1)
                    closeModal3()
                }
              })
              .catch(error => {
                  console.error('Error fetching tree data:', error);
              });
        }

        const [selectBook,setSelectBook] = useState([])

        const handleCheckboxChange3 = (index, isChecked) => {
            if (isChecked) {
                setSelectBook(prevIds => [...prevIds, index]);
            } else {
                setSelectBook(prevIds => prevIds.filter(id => id !== index));
            }
        }

        const getExamById = (id) => {
            const examItem = examList.find(exam => exam.exam_id === id);
            return examItem ? examItem.exam_name : 'Unknown';
        };
        //문제집 한글로 열기
        const pythonOpenBook = (modeNum) =>{
            var url = new URL('http://localhost:8000/manage_question/makeWorkBook');
            var params = { 
                    mode: modeNum,
                    user_name: userInfo.id,
                    workbook_id: selectedBookId.id,
                    image_url : userInfo.pictureURL
         }
        fetch(url,{
            method : 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(params)
        })
        .then(response => response.text())
        .then(data => alert(data))
        .catch(error => {console.error('Error:', error)
            alert('오류발생 : 파이썬 실행 상태를 확인해주세요')
        });
        }
        //문제집 삭제
        const deleteBook = () =>{
            const result = window.confirm("선택된 문제집를 삭제하시겠습니까? (배정된 문제집도 삭제됨)");
            if (result) {
                    axios({
                        method: "POST",
                        url: `${back_ip_url}/deleteBook`,
                        data : {
                        student_id : selectedStu,
                        book_id: selectedBookId.id,
                        userId : userInfo.id

                    },
                        withCredentials : true,
                    })
                    .then(response => {
                    if (response.status === 200) {
                        alert(response.data)
                        setCount(count+1)
                    }
                    })
                    .catch(error => {
                        console.error('Error delete book:', error);
                    });
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }
        }
        //문제집 배정
        const bookToStudent = () =>{
            axios({
                method: "POST",
                  url: `${back_ip_url}/bookToStudent`,
                  data : {
                    student_id : selectedStu,
                    bookInfo: selectedBookId,
                    userId : userInfo.id
                },
                  withCredentials : true,
              })
              .then(response => {
                if (response.status === 200) {
                    alert(response.data)
                    setCount(count+1)
                }
              })
              .catch(error => {
                  console.error('Error book to student:', error);
              });
        }
        //임시 함수 버튼
        const examCreate3 = () =>{
            var url = new URL('http://localhost:8000/manage_question/selectElement');
            var params = { 
                        user_name : userInfo.id,
                        exam_id : selectedExam.exam_id
             };
            fetch(url,{
                method : 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(params)
            })
            .then(response => response.text())
            .then(data => alert(data))
            .catch(error => {console.error('Error:', error)
                alert('오류발생 : 파이썬 실행 상태를 확인해주세요')
            })
        }
    //시험지 페이지화
    const itemsPerPage = 20; // 페이지당 항목 수
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage_designated_exam, setCurrentPage_designated_exam] = useState(1);
    const [currentPage_All_Workbook, setCurrentPage_All_Workbook] = useState(1);
    const [currentPage_designated_Workbook, setCurrentPage_designated_Workbook] = useState(1);




    // 총 페이지 수 계산
    const totalPages = Math.ceil(filteredExamList.length / itemsPerPage);

    // 현재 페이지에 보여질 항목들 계산
    const currentItems = filteredExamList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // 페이지 번호 클릭 핸들러
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSelectedRow2()
    };

    ///배정된 시험지
    // 총 페이지 수 계산 
    const totalPages_designated_exam = Math.ceil(mergedData.length / itemsPerPage);

    // 현재 페이지에 보여질 항목들 계산
    const currentItems_designated_exam = mergedData.slice(
        (currentPage_designated_exam - 1) * itemsPerPage,
        currentPage_designated_exam * itemsPerPage
    );
    // 페이지 번호 클릭 핸들러
    const handlePageChange2 = (pageNumber) => {
        setCurrentPage_designated_exam(pageNumber);
        setSelectedRow2()
    };

    ///전체 문제집
    const totalPages_All_Workbook = Math.ceil(filteredbookList.length / itemsPerPage);

    // 현재 페이지에 보여질 항목들 계산
    const currentItems_All_Workbook = filteredbookList.slice(
        (currentPage_All_Workbook - 1) * itemsPerPage,
        currentPage_All_Workbook * itemsPerPage
    );
    // 페이지 번호 클릭 핸들러
    const handlePageChange3 = (pageNumber) => {
        setCurrentPage_All_Workbook(pageNumber);
        setSelectedRow2()
    };

    ///배정된 문제집
    const totalPages_designated_Workbook = Math.ceil(mergedBookData.length / itemsPerPage);

    // 현재 페이지에 보여질 항목들 계산
    const currentItems_designated_Workbook = mergedBookData.slice(
        (currentPage_designated_Workbook - 1) * itemsPerPage,
        currentPage_designated_Workbook * itemsPerPage
    );
    // 페이지 번호 클릭 핸들러
    const handlePageChange4 = (pageNumber) => {
        setCurrentPage_designated_Workbook(pageNumber);
        setSelectedRow2()
    };

    /// 학생확인버튼
    const checkStudentInCourse = (index) =>{
        setModalIsOpen_checkStudent(true)
        setCourseIndex(index)
        console.log(courseIndex)
    }
//////////

return(
    <div>
        <Modal className="Modal_s2"isOpen={isPopoverOpen1} onRequestClose={() => openPopover1()}>
            <div className="Modal_head">
                <h2>시험지 조회 선택</h2>
                <button className="xMark" onClick={openPopover1}><IoMdClose /></button>
            </div>
            <div className="pythonView">
                <button style={{width:"100%"}} className="pythonViewbutton buttonA btnColorA" onClick={()=>pythonOpenExam(0,selectedExam.exam_id)}>학생용 조회</button>
                <button style={{width:"100%"}} className="pythonViewbutton buttonA btnColorA" onClick={()=>pythonOpenExam(1,selectedExam.exam_id)}>선생님용 조회</button>
                <button style={{width:"100%"}} className="pythonViewbutton buttonA btnColorA" onClick={()=>pythonOpenExam(2,selectedExam.exam_id)}>간편 정답</button>
            </div>
        </Modal>
        <Modal className="Modal_s2"isOpen={isPopoverOpen2} onRequestClose={() => openPopover2()}>
            <div className="Modal_head">
                <h4>배정된 시험지 조회 선택</h4>
                <button className="xMark" onClick={openPopover2}><IoMdClose/></button>
            </div>
            <div  className="pythonView">
            {SelectedMergedData ? (
            <>
                <button
                style={{width:"100%"}}
                className="pythonViewbutton buttonA btnColorA "
                onClick={() => pythonOpenExam(0, SelectedMergedData.exam_id)}
                >
                학생용 조회
                </button>
                <button
                style={{width:"100%"}}
                className="pythonViewbutton buttonA btnColorA"
                onClick={() => pythonOpenExam(1, SelectedMergedData.exam_id)}
                >
                선생님용 조회
                </button>
                <button
                style={{width:"100%"}}
                className="pythonViewbutton buttonA btnColorA"
                onClick={() => pythonOpenExam(2, SelectedMergedData.exam_id)}
                >
                간편 정답
                </button>
            </>
            ) : <div>시험지을 선택해주세요</div>}
            </div>
        </Modal>
        <Modal className="Modal_s2"isOpen={isPopoverOpen3} onRequestClose={() => openPopover3()}>
            <div className="Modal_head">
                <h2>문제집 조회 선택</h2>
                <button className="xMark" onClick={openPopover3}><IoMdClose /></button>
            </div>
            <div className="pythonView">
                <button
                style={{width:"100%"}}
                className="pythonViewbutton buttonA btnColorA confi"
                onClick={() => pythonOpenBook(0)}
                >
                학생용 조회
                </button>
                <button
                style={{width:"100%"}}
                className="pythonViewbutton buttonA btnColorA"
                onClick={() => pythonOpenBook(1)}
                >
                선생님용 조회
                </button>
                <button
                style={{width:"100%"}}
                className="pythonViewbutton buttonA btnColorA"
                onClick={() => pythonOpenBook(2)}
                >
                간편 정답
                </button>
            </div>
        </Modal>
        <Modal className="Modal_testCreate"isOpen={modalIsOpen} onRequestClose={() => closeModal()}>
                <TreeViewer2 closeModal={closeModal} nextModal={nextModal} chosenItem2={chosenItem2} setChosenItem2={setChosenItem2}/>
        </Modal>
        <Modal className="Modal_testCreate"isOpen={modalIsOpenTest} onRequestClose={() => closeModal()}>
            <TestInfo closeModal={closeModal} prevModal={prevModal} chosenItem2={chosenItem2} setChosenItem2={setChosenItem2} treeData2={treeData}/>
        </Modal>
        <Modal className="Modal_classmanager" isOpen={modalIsOpen_checkStudent} onRequestClose={() => setModalIsOpen_checkStudent(false)}>
            <div className="Modal_head">
                <h2>수강생 확인</h2>
                <button className="xMark" onClick={() => setModalIsOpen_checkStudent(false)}><IoMdClose /></button>
            </div>
            {courseIndex > -1 ? (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>학생 ID</th>
                                <th>학생 이름</th>
                                <th>학교/학년</th>
                                <th>학생 연락처</th>
                                <th>학부모 연락처</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentCourseData.map((studentCourse, index) => {
                                if (studentCourse.Course_id === courseData[courseIndex].course_id) {
                                    const student = studentData.find(student => student.student_id === studentCourse.Student_id);
                                    if (student) {
                                        return (
                                            <tr key={index} >
                                                <td>{student.student_id}</td>
                                                <td>{student.student_name}</td>
                                                <td>{getGradeName(student.grade)}</td>
                                                <td>{student.phoneNumber}</td>
                                                <td>{student.parent_phone}</td>
                                            </tr>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </tbody>
                    </table>
                </div>
            ) : ('선택되지 않음')}
        </Modal>
        <Header></Header>
        <div className="main">
            <div className="studentList" style={{}}>
                <ul className="tab_area2">
                    <li className={activeTab === 'student' ? 'active' : ''} style={{marginRight : "0px"}}>
                        <span onClick={switchBtn2}>학생별</span>
                    </li>
                    <li className={activeTab === 'course' ? 'active' : ''}>
                        <span onClick={switchBtn}>반별</span>
                    </li>
                </ul>
                <div className="divtable listHeader_Stu block" id="listHeader_Stu">
                    <table>
                        <thead>
                        <tr>
                            <th class="th-author">이름</th>
                            <th class="grade_name">학년</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {studentData ? (studentData.map((student, index) => (
                                <tr key={student.student_id} style={{ backgroundColor: 'white' }} onClick={() => handleRowClick(index)}>
                                    <td>{student.student_name}</td>
                                    <td>{getGradeName(student.grade)}</td>
                                    <td> {/* 선택 버튼 */}
                                        <input type="checkbox" onChange={(e) => handleCheckboxChange(student.student_id, e.target.checked)} />
                                    </td>
                                </tr>
                            ))) : ('학생 정보 불러오는 중...')}
                        </tbody>
                    </table>
                </div>
                <div className="divtable listHeader_Class none" id="listHeader_Class">
                    <table>
                        <thead>
                            <th>반 이름</th>
                            <th>선택</th>
                            <th>학생확인</th>
                        </thead>
                        <tbody>
                            {courseData ? (courseData.map((course, index) => (
                                    <tr key={course.course_id} style={{backgroundColor:"white"}}>
                                        <td>{course.course_name}</td>
                                        <td> {/* 선택 버튼 */}
                                            <input type="checkbox" onChange={(e) => handleCheckboxChange2(course.course_id ,e.target.checked)} />
                                        </td>
                                        <td>
                                            <button className="xMark" onClick={()=>checkStudentInCourse(index)}><IoMdPeople style={{color : "black" ,cursor : "pointer"}}/></button>
                                        </td>
                                    </tr>
                                ))) : ('강의 정보 불러오는 중...') }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="testPage">
                <div className="topBtn btnBarMin680">
                    <button onClick={exam_switchBtn} id="exam_all2" className="buttonA btnColorA block">배정된 시험지 보기</button>
                    <button onClick={exam_switchBtn2} id="exam_designated2" className="buttonA btnColorA none">전체 시험지 보기</button>
                    <button onClick={exam_switchBtn5} id="book_all2" className="buttonA btnColorA none">배정된 문제집 보기</button>
                    <button onClick={exam_switchBtn6} id="book_designated2" className="buttonA btnColorA none">전체 문제집 보기</button>
                    <button onClick={exam_switchBtn3} id="seeBook" className="buttonA btnColorA block">문제집 보기</button>
                    <button onClick={exam_switchBtn4} id="seeExam" className="buttonA btnColorA none">시험지 보기</button>
                    <label for="start-date"style={{fontSize:'15px',marginBottom:'8px',marginLeft:'5px'}}>날짜 선택 :</label>
                    <input type="date" id="start-date"/>
                    <label for="end-date"> ~</label>
                    <input type="date" id="end-date"/>
                    <button onClick={dateSearch} className="xMark" style={{fontSize:'15px',marginBottom:'10px',marginLeft:'5px'}}>검색 <FaSearch /></button>
                </div>
                <div className="divtable exam_all block" id="exam_all" style={{ position: 'relative' }}>
                    <table>
                        <thead>
                            <tr>
                                <th className="th-name">시험지 이름</th>
                                <th className="th-count">문항 수</th>
                                <th className="th-date">생성일</th>
                                <th className="th-author">출제자</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((examList, index) => (
                                    <tr key={index} style={{ backgroundColor: selectedRow2 === index 
                                        ? 'lightblue' : 'white' }} onClick={() => handleRowClick2(index)}>
                                        <td>{examList.exam_name}</td>
                                        <td>{examList.items}</td>
                                        <td>{formatDate(examList.date)}</td>
                                        <td>{examList.teacher_name}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    {currentItems.length === 0 ? (<td colSpan="4">시험지 정보가 없습니다</td>) : (<td colSpan="4">시험지 정보 불러오는 중...</td>)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="pagination"
                    style={{
                    position: 'absolute',
                    bottom: '5px', // 부모 요소의 하단에 고정
                    width: '90%'
                    }}>
                        <button 
                            onClick={() => handlePageChange(currentPage - 1)} 
                            disabled={currentPage === 1}
                            style={{top:'5px'}}
                        >
                            <FaAngleLeft />
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i + 1}
                                onClick={() => handlePageChange(i + 1)}
                                className={currentPage === i + 1 ? 'active' : ''}
                                style={{
                                    marginTop: '-4px'  // 버튼을 위로 3px 이동
                                }}
                            >
                                {i + 1}
                            </button>
                        ))}
                        <button 
                            onClick={() => handlePageChange(currentPage + 1)} 
                            disabled={currentPage === totalPages}
                        >
                            <FaAngleRight />
                        </button>
                    </div>
                </div>
                <div className="divtable exam_designated none" id="exam_designated" style={{ position: 'relative' ,}}>
                    <table>
                        <thead>
                            <th class="th-name">시험지 이름</th>
                            <th class="th-count">문항 수</th>
                            <th>학생 이름</th>
                            <th class="th-date">생성일</th>
                            <th class="th-author">출제자</th>
                            <th>정답/오답</th>
                        </thead>
                        <tbody>
                            {currentItems_designated_exam.map((data, index) => (
                                <tr key={index} style={{ backgroundColor: selectedRow3 === index 
                                    ? 'lightblue' : 'white' }}  onClick={() => handleRowClick3(index)}>
                                    <td>{data.exam_name}</td>
                                    <td>{data.items}</td>
                                    <td>{data.student.student_name}</td>
                                    <td>{formatDate(data.date)}</td>
                                    <td>{data.teacher_name}</td>
                                    <td>{data.score !== null ? (data.items - data.score) + ' / ' + data.score : '미채점' }</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination"
                    style={{
                    position: 'absolute',
                    bottom: '5px',
                    width: '90%',
                    }}>
                        <button 
                            onClick={() => handlePageChange2(currentPage_designated_exam - 1)} 
                            disabled={currentPage_designated_exam === 1}
                            style={{top:'5px'}}
                        >
                            <FaAngleLeft />
                        </button>
                        {Array.from({ length: totalPages_designated_exam }, (_, i) => (
                            <button
                                key={i + 1}
                                onClick={() => handlePageChange2(i + 1)}
                                className={currentPage_designated_exam === i + 1 ? 'active' : ''}
                                style={{
                                    marginTop: '-4px'  // 버튼을 위로 3px 이동
                                }}
                            >
                                {i + 1}
                            </button>
                        ))}
                        <button 
                            onClick={() => handlePageChange2(currentPage_designated_exam + 1)} 
                            disabled={currentPage_designated_exam === totalPages_designated_exam }
                        >
                            <FaAngleRight />
                        </button>
                    </div>
                </div>
                <div className="divtable book_all none" id="book_all" style={{ position: 'relative' ,Width : '615px'}}>
                    <table>
                        <thead>
                            <th class="th-name">문제집 이름</th>
                            <th class="th-date">출제일</th>
                            <th class="th-author">출제자</th>
                        </thead>
                        <tbody>
                            {currentItems_All_Workbook ? (currentItems_All_Workbook.map((workbook, index) => (
                                <tr key={index} style={{ backgroundColor: selectedRow5 === index 
                                    ? 'lightblue' : 'white' }} onClick={() => handleRowClick5(index)}>
                                <td>{workbook.name}</td>
                                <td>{formatDate(workbook.date)}</td>
                                <td>{workbook.teacher_name}</td>
                                </tr>
                            ))) : ('시험지 정보 불러오는 중...')}
                        </tbody>
                    </table>
                    <div className="pagination"
                    style={{
                    position: 'absolute',
                    bottom: '5px',
                    width: '90%',
                    }}>
                        <button 
                            onClick={() => handlePageChange3(currentPage_All_Workbook - 1)} 
                            disabled={currentPage_All_Workbook === 1}
                            style={{top:'5px'}}
                        >
                            <FaAngleLeft />
                        </button>
                        {Array.from({ length: totalPages_All_Workbook }, (_, i) => (
                            <button
                                key={i + 1}
                                onClick={() => handlePageChange3(i + 1)}
                                className={currentPage_All_Workbook === i + 1 ? 'active' : ''}
                                style={{
                                    marginTop: '-4px'  // 버튼을 위로 3px 이동
                                }}
                            >
                                {i + 1}
                            </button>
                        ))}
                        <button 
                            onClick={() => handlePageChange3(currentPage_All_Workbook + 1)} 
                            disabled={currentPage_All_Workbook === totalPages_All_Workbook }
                        >
                            <FaAngleRight />
                        </button>
                    </div>
                </div>
                <div className="divtable book_designated none" id="book_designated" style={{ position: 'relative' ,Width : '600px'}}>
                    <table>
                        <thead>
                            <th class="th-name">문제집 이름</th>
                            <th>시험지 이름</th>
                            <th>학생 이름</th>
                            <th class="th-date">생성일</th>
                            <th>정답/오답</th>
                        </thead>
                        <tbody>
                            {currentItems_designated_Workbook.map((data, index) => (
                                    <tr key={index} style={{ backgroundColor: selectedRow6 === index 
                                        ? 'lightblue' : 'white' }}  onClick={() => handleRowClick6(index)}>
                                        <td>{data.name}</td>
                                        <td>{data.exam_name}</td>
                                        <td>{data.student.student_name}</td>
                                        <td>{formatDate(data.date)}</td>
                                        <td>{data.score !== null ? (data.items - data.score) + ' / ' + data.score : '미채점' }</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div className="pagination"
                    style={{
                    position: 'absolute',
                    bottom: '5px',
                    width: '90%',
                    }}>
                        <button 
                            onClick={() => handlePageChange4(currentPage_designated_Workbook - 1)} 
                            disabled={currentPage_designated_Workbook === 1}
                            style={{top:'5px'}}
                        >
                            <FaAngleLeft />
                        </button>
                        {Array.from({ length: totalPages_designated_Workbook }, (_, i) => (
                            <button
                                key={i + 1}
                                onClick={() => handlePageChange4(i + 1)}
                                className={currentPage_designated_Workbook === i + 1 ? 'active' : ''}
                                style={{
                                    marginTop: '-4px'  // 버튼을 위로 3px 이동
                                }}
                            >
                                {i + 1}
                            </button>
                        ))}
                        <button 
                            onClick={() => handlePageChange4(currentPage_designated_Workbook + 1)} 
                            disabled={currentPage_designated_Workbook === totalPages_designated_Workbook }
                        >
                            <FaAngleRight />
                        </button>
                    </div>
                </div>
                <div className="bottomBtn btnBarMin680 block" id="exam_all3">
                    <button className="buttonA btnColorA" disabled={selectedRow2==null || selectedExam.tree_ids !== null} onClick={openPopover1}><LuFileSearch className='iconA' style={{ fontSize: '18px' }}/> 시험지 조회</button>
                    <button className="buttonA btnColorA" onClick={openCreateExam}><TiDocumentAdd className='iconA' style={{ fontSize: '20px' }}/> 시험지 추가</button>
                    {/* <button className="buttonA btnColorA" onClick={openCreateExamTest}><TiDocumentAdd className='iconA' style={{ fontSize: '20px' }}/> 시험지 추가2</button> */}
                    <button className="buttonA btnColorA" disabled={selectedRow2==null} onClick={DeleteExam}><TiDocumentDelete className='iconA' style={{ fontSize: '20px' }}/> 시험지 삭제</button>
                    <button className="buttonA btnColorA" onClick={examCreate3} disabled={selectedExam.tree_ids == null}>문제 선택</button>
                    <button className="buttonA btnColorA block" disabled={selectedRow==null || selectedRow2==null} onClick={examToStudent} id="button_Stu">선택학생에게 배정</button>
                    <button className="buttonA btnColorA none" disabled={selectedClass==null || selectedRow2==null} id="button_Class" style={{ width: '130px' }}> 선택 반 전체 배정</button>
                    {/* <button onClick={LogCheck}>log</button> */}
                </div>
                <div className="bottomBtn btnBarMin680 none" id="exam_designated3">
                    <button className="buttonA btnColorA" disabled={selectedRow3==null} onClick={openPopover2}><LuFileSearch className='iconA' style={{ fontSize: '20px' }}/>시험지 조회</button>
                    <button className="buttonA btnColorA" disabled={selectedRow3==null} onClick={ScoreExam}><GrScorecard className='iconA' style={{ fontSize: '18px' }}/> 시험지 채점</button>
                    <button className="buttonA btnColorA" onClick={()=>makeRetest()} disabled={condition == 1 && selectedRow3==null}>오답문제 재출제</button>
                    <button className="buttonA btnColorA" onClick={()=>makeRetest2()} disabled={condition == 1 && selectedRow3==null}>오답유사문항 출제</button>
                    <button className="buttonA btnColorA" disabled={selectedRow3==null} onClick={DeleteExamStudent}><LuFileMinus2 className='iconA' style={{ fontSize: '20px' }}/> 배정 취소</button>
                    <button onClick={LogCheck}>LOG CHECK</button>
                </div>
                <div className="bottomBtn book_all btnBarMin680 none" id="book_all3">
                    <button className="buttonA btnColorA" onClick={openPopover3}><LuFileSearch className='iconA' style={{ fontSize: '20px' }}/> 문제집 조회</button>
                    <button className="buttonA btnColorA" onClick={openBookModal}><IoDocumentsOutline className='iconA' style={{ fontSize: '20px' }}/> 문제집 만들기</button>
                    <button className="buttonA btnColorA" onClick={deleteBook}><TiDocumentDelete className='iconA' style={{ fontSize: '20px' }}/> 문제집 삭제</button>
                    <button className="buttonA btnColorA" onClick={bookToStudent} disabled={selectedRow==null || selectedRow5==null} style={{ width: "150px" }}>선택된 학생에게 배정</button>
                    <button onClick={LogCheck3}>LOG CHECK</button>
                </div>
                <div className="bottomBtn book_designated btnBarMin680 none" id="book_designated3">
                    <button className="buttonA btnColorA" onClick={DeleteBookStudent}><LuFileMinus2 className='iconA' style={{ fontSize: '20px' }} /> 배정 취소</button>
                    <button className="buttonA btnColorA" onClick={ScoreExamBook}><GrScorecard className='iconA' style={{ fontSize: '18px' }}/> 시험지 채점</button>
                    <button  onClick={LogCheck3}>LOG CHECK</button>
                </div>
            </div>
        </div>
        <Modal className="Modal_Score"isOpen={modalIsOpen2} onRequestClose={() => closeModal2()}>
            <div className="Modal_head">
                <h2>채점</h2>
                <button className="xMark" onClick={() => closeModal2()}><IoMdClose /></button>
            </div>
            <div style={{display:'flex',flexDirection:"column",alignItems:'center'}}>
                {selectedRow3 !== undefined ? (
                    <div className="scoreInfo">
                        <p>시험지명: {currentItems_designated_exam[selectedRow3].exam_name}</p>
                        <p>학생명: {currentItems_designated_exam[selectedRow3].student.student_name}</p>
                    </div>
                ):(<p>d</p>)}
                {markAnswer && markAnswer.length > 0 ? (
                    markAnswer.map((markAnswer, index) => (
                        <div key={index} className="mark-answer-item">
                            <p style={{width:'105px'}}>문제 {index + 1}번 답:</p>
                            <p style={{width:'110px'}}>{markAnswer.answer_text}</p>
                            {/* O/X 체크 버튼 */}
                            <button  className="oMark" onClick={() => handleMark(index, true)}><FaRegCheckCircle  style={{ color: colorO[index] }}/></button>
                            <button  className="xMark2" onClick={() => handleMark(index, false)}><FaRegTimesCircle style={{ color: colorX[index] }}/></button>
                        </div>
                    ))
                ) : (
                    <p>No mark answers available.</p>
                )}
                <button className="buttonB btnColorA confirm_btn" style={{margin:'10px'}} onClick={saveScore}>저장</button>
                <button onClick={LogCheck2}>LOG 확인</button>
            </div>
        </Modal>
        <Modal className="Modal_Score" isOpen={modalIsOpen4} onRequestClose={() => closeModal4()}>
            <div className="Modal_head">
                <h2>채점</h2>
                <button className="xMark" onClick={() => closeModal4()}><IoMdClose /></button>
            </div>
            <div>
                {selectedRow6 !== undefined ? (
                    <div className="scoreInfo">
                        <p>문제집 이름: {selectedBookId_designated.name}</p>
                        <p>시험지명: {selectedBookId_designated.exam_name}</p>
                        <p>학생명: {selectedBookId_designated.student.student_name}</p>
                    </div>
                ):(<p>d</p>)}
                {markAnswer && markAnswer.length > 0 ? (
                    markAnswer.map((markAnswer, index) => (
                        <div key={index} className="mark-answer-item">
                            <p>문제 {index + 1}번 답: {markAnswer.answer_text}</p>
                            {/* O/X 체크 버튼 */}
                            <button className="oMark" onClick={() => handleMark(index, true)}><FaRegCheckCircle  style={{ color: colorO[index] }}/></button>
                            <button className="xMark2" onClick={() => handleMark(index, false)}><FaRegTimesCircle style={{ color: colorX[index] }}/></button>
                        </div>
                    ))
                ) : (
                    <p>No mark answers available.</p>
                )}
                <button className="buttonB btnColorA confirm_btn" style={{margin:'10px'}}onClick={saveScore2}>저장</button>
                <button onClick={LogCheck4}>LOG 확인</button>
            </div>
        </Modal>
        <Modal isOpen={modalIsOpen3} onRequestClose={() => closeModal3()}>
            <div className="Modal_head">
                <h2>문제집 만들기</h2>
                <button className="xMark" onClick={() => closeModal3()}><IoMdClose /></button>
            </div>
            <div>

            <h3>시험지 선택</h3>
            <table>
                <thead>
                    <th>시험지 이름</th>
                    <th>문항 수</th>
                    <th>생성일</th>
                    <th>출제자</th>
                    <th>선택</th>
                </thead>
                <tbody>
                    {examList ? (examList.map((examList, index) => (
                        <tr key={index} style={{ backgroundColor: selectedRow4 === index 
                            ? 'lightblue' : 'white' }} onClick={() => handleRowClick4(index)}>
                        <td>{examList.exam_name}</td>
                        <td>{examList.items}</td>
                        <td>{formatDate(examList.date)}</td>
                        <td>{examList.teacher_name}</td>
                        <td> {/* 선택 버튼 */}
                            <input type="checkbox" onChange={(e) => handleCheckboxChange3(examList.exam_id,e.target.checked)} />
                        </td>
                        </tr>
                    ))) : ('시험지 정보 불러오는 중...')}
                </tbody>
            </table>
            <h3>선택된 시험지</h3>
                <ul>
                    {selectBook.map((item, index) => (
                        <li key={index}>
                            {getExamById(item)}
                        </li>
                    ))}
                </ul>
            </div>
            <input placeholder="문제집 이름 입력" onChange={(e)=>setBookName(e.target.value)}></input>
            <button onClick={makeBook}>선택된 시험지로 문제집 만들기</button>
        </Modal>
    </div>
)
}