import Header from "../Header/Header"
import './ClassManagement.css'
import UserInfo from '../UserInfo/UserInfo.jsx'
import { useState , useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal'
import { FaPersonCirclePlus,FaPersonCircleMinus,FaPersonCircleExclamation ,FaPersonCircleQuestion   } from "react-icons/fa6";
import { FaPencilAlt } from "react-icons/fa";
import { CgRemoveR,CgAddR  } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";




export default function ClassManagement(){

    const userInfo = UserInfo().userInfo;
    const isLogin = UserInfo().isLogin;
    const back_ip_url = process.env.REACT_APP_SERVER_URL
    const [studentData,setStudentData] = useState()
    const [selectedRow, setSelectedRow] = useState();
    const [courseData,setCourseData] = useState()
    const [studentCourseData,setStudentCourseData] = useState();
    const [selectedRow2, setSelectedRow2] = useState();
    const [selectedRow3, setSelectedRow3] = useState();
    const [selectedRow4, setSelectedRow4] = useState();
    const [selectedRow5, setSelectedRow5] = useState();
    const [newSchoolGrade, setNewSchoolGrade] = useState('');
    const [editSchoolGrade, setEditSchoolGrade] = useState('');

    const school_grade = [
        { id: 15, name: "============" },
        { id: 1, name: "초등학교 1학년" },
        { id: 2, name: "초등학교 2학년" },
        { id: 3, name: "초등학교 3학년" },
        { id: 4, name: "초등학교 4학년" },
        { id: 5, name: "초등학교 5학년" },
        { id: 6, name: "초등학교 6학년" },
        { id: 13, name: "============" },
        { id: 7, name: "중학교 1학년" },
        { id: 8, name: "중학교 2학년" },
        { id: 9, name: "중학교 3학년" },
        { id: 14, name: "============" },
        { id: 10, name: "고등학교 1학년" },
        { id: 11, name: "고등학교 2학년" },
        { id: 12, name: "고등학교 3학년" }, 
    ];
        // 학년 ID를 이름으로 변환하는 함수
        const getGradeName = (gradeId) => {
            const grade = school_grade.find(g => g.id == gradeId);
            return grade ? grade.name : '학년 정보 없음';
        };
    
        //전화번호 입력 함수
        function oninputPhone(target) {
            target.value = target.value
                .replace(/[^0-9]/g, '')
                .replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, "$1-$2-$3");
        }
        //현재 날짜
        const [currentDate, setCurrentDate] = useState('');
        useEffect(() => {
            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD' 형식으로 포맷팅
            setCurrentDate(formattedDate);
        }, []);

        const formatDate = (dateString) => {
            if (!dateString) {
                return ''; // dateString이 null이나 undefined인 경우 빈 문자열 반환
            }
        
            const date = new Date(dateString); // Date 객체로 변환
        
            // UTC 시간을 9시간 더해서 한국 시간대로 변환
            const kstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
        
            const year = kstDate.getFullYear();
            const month = String(kstDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
            const day = String(kstDate.getDate()).padStart(2, '0');
        
            return `${year}-${month}-${day}`; // 'YYYY-MM-DD' 형식으로 반환
        };

        
    const fetchStudentData = () =>{
    axios({
        method: "GET",
          url: `${back_ip_url}/fetchStudentData`,
          params: userInfo,
          withCredentials : true,
      })
      .then(response => {
          setStudentData(response.data);
      })
      .catch(error => {
          console.error('Error fetching tree data:', error);
      });
    }

    const fetchCourseData = () =>{
        axios({
            method: "GET",
              url: `${back_ip_url}/fetchCourseData`,
              params: userInfo,
              withCredentials : true,
          })
          .then(response => {
                setCourseData(response.data);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
        }
    
    const fetchStudentCourseData = () =>{
        axios({
            method: "GET",
              url: `${back_ip_url}/fetchStudentCourseData`,
              params: userInfo,
              withCredentials : true,
          })
          .then(response => {
                setStudentCourseData(response.data);
          })
          .catch(error => {
              console.error('Error fetching tree data:', error);
          });
    }


    useEffect(() => {
    if (isLogin) {  // 로그인 상태인지 확인
        const timer = setTimeout(() => {
            // 서버로부터 학생, 수강반 데이터 가져오기
            fetchStudentData();
            fetchCourseData();
            fetchStudentCourseData();
        }, 100); // 0.1초 뒤에 실행

        return () => clearTimeout(timer);
    }
    }, [userInfo])

    const [activeTab, setActiveTab] = useState('student'); // 기본 상태는 'student'로 설정

    function switchBtn(){
        document.getElementById('student').classList.replace('none', 'block');
        document.getElementById('course').classList.replace('block', 'none');
        setActiveTab('student')
    }
    function switchBtn2(){
        document.getElementById('student').classList.replace('block', 'none');
        document.getElementById('course').classList.replace('none', 'block');
        setActiveTab('course')
    }
    
    //로그 체크용
    const logcheck = () =>{
        console.log(selectedRow2)
        console.log("studentCourseData",studentCourseData)
        console.log("studentData[selectedRow].student_id",studentData[selectedRow].student_id)
        console.log("studentCourseData[selectedRow5]",studentCourseData[selectedRow5])

    }

    const handleRowClick = (index) => {
        setSelectedRow(index);
    }
    const handleRowClick2 = (index) => {
        setSelectedRow2(index);
    }
    const handleRowClick3 = (index) => {
        setSelectedRow3(index);
    }
    const handleRowClick4 = (index) => {
        setSelectedRow4(index);
    }
    const handleRowClick5 = (index) => {
        setSelectedRow5(index);
    }
    

    //Modal
    const [modalIsOpen,setModalIsOpen] = useState(false)
    const [modalIsOpen2,setModalIsOpen2] = useState(false)
    const [modalIsOpen3,setModalIsOpen3] = useState(false)
    const [modalIsOpen4,setModalIsOpen4] = useState(false)
    const [modalIsOpen5,setModalIsOpen5] = useState(false)
    const [modalIsOpen6,setModalIsOpen6] = useState(false)
    const [modalIsOpen7,setModalIsOpen7] = useState(false)


    //Modal 확장 함수
    const openAddStudent = () =>{
        setModalIsOpen(true)
    }
    const openEditStudent = () =>{
        setModalIsOpen2(true)
    }
    const openStudentCourse = () =>{
        setModalIsOpen3(true)
    }
    const openAddCourse = () =>{
        setModalIsOpen4(true)
    }
    const openEditCourse = () =>{
        setModalIsOpen5(true)
    }
    const openCheckStudent = () =>{
        setModalIsOpen6(true)
    }
    const openNewStudentCourse = () =>{
        setModalIsOpen7(true)
    }
    //버튼 함수

    //신규 학생 등록
    const [newName,setNewName] = useState()
    const [newPhone,setNewPhone] = useState()
    const [newPPhone,setNewPPhone] = useState()

    const addStudent = () =>{
        console.log(newName,newSchoolGrade,newPhone,'학생 등록 정보')
        axios({
            url : `${back_ip_url}/addStudent`,
            data:{
                name : newName,
                grade : newSchoolGrade,
                phone : newPhone,
                parent_phone :newPPhone,
                date : currentDate,
                userId : userInfo.id
            },
            withCredentials: true,
            method: "POST"
        }).then(response =>{
            if (response.status === 200){
                alert("신규 학생 등록 완료!");
                setModalIsOpen(false)
                fetchStudentData();
            }
        })
    }

    // 학생 정보 삭제
    const deleteStudent = () =>{
        const deleteID = studentData[selectedRow].student_id
        const result = window.confirm("선택된 학생정보를 삭제하시겠습니까?");
            if (result) {
                axios({
                    url : `${back_ip_url}/deleteStudent`,
                    data : {
                        data :deleteID,
                        userId : userInfo.id
                    },
                    withCredentials: true,
                    method: "POST"
                }).then(response=>{
                    if (response.status === 200){
                        alert("삭제 완료")
                        fetchStudentData();
                        setSelectedRow(0)
                    }
                }).catch(error => 
                    console.error('Error:', error));
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }
        
    }


    //학생 정보 수정
    const [editName,setEditName] = useState()
    const [editPhone,setEditPhone] = useState()
    const [editPPhone,setEditPPhone] = useState()
    const [editGrade,setEditGrade] = useState()

    // useEffect(() =>{
    //     setEditName(studentData[selectedRow].student_name)
    //     setEditAddress(studentData[selectedRow].home_address)
    //     setEditPhone(studentData[selectedRow].phoneNumber)
    //     setEditSchool(studentData[selectedRow].school_name)
    // },[modalIsOpen2])

    useEffect(() => {
        if (selectedRow > -1 && studentData[selectedRow]) {
        setEditName(studentData[selectedRow].student_name);
        setEditSchoolGrade(studentData[selectedRow].grade);
        setEditPhone(studentData[selectedRow].phoneNumber);
        setEditPPhone(studentData[selectedRow].parent_phone);
        }
    }, [selectedRow, studentData]);
    const editStudent = () =>{
        const ID = studentData[selectedRow].student_id
        axios({
            url: `${back_ip_url}/editStudent`,
            data : {
                id : ID,
                name : editName,
                grade : editSchoolGrade,
                phone : editPhone,
                parent_phone :editPPhone,
                userId : userInfo.id
            },
            method: "POST",
            withCredentials: true,
        }).then(response=>{
            if (response.status === 200){
                alert("수정 완료")
                setModalIsOpen2(false)
                fetchStudentData();
                setEditName();
                setEditPhone();
                setEditPPhone();
                setEditSchoolGrade();
            }
        }).catch(error => 
            console.error('Error:', error));
    }

    //신규 강의 등록
    const [newCourse,setNewCourse] = useState()
    const [startTime,setStartTime] = useState()
    const [endtTime,setEndTime] = useState()
    var checkedDays = [];

    function saveCheckedDays() {
        
        // 체크된 체크박스 요소를 확인하여 배열에 저장
        if (document.getElementById("mon").checked) checkedDays.push("월");
        if (document.getElementById("tue").checked) checkedDays.push("화");
        if (document.getElementById("wed").checked) checkedDays.push("수");
        if (document.getElementById("thu").checked) checkedDays.push("목");
        if (document.getElementById("fri").checked) checkedDays.push("금");
        if (document.getElementById("sat").checked) checkedDays.push("토");
        if (document.getElementById("sun").checked) checkedDays.push("일");
        console.log("체크된 요일:", checkedDays);
        axios({
            url: `${back_ip_url}/addCourse`,
            data : {
                name : newCourse,
                start : startTime,
                end : endtTime,
                day : checkedDays,
                userId : userInfo.id
            },
            method: "POST",
            withCredentials: true,
        }).then(response=>{
            if (response.status === 200){
                alert("강의 등록 완료")
                setModalIsOpen4(false)
                fetchStudentData();
                fetchCourseData();
                setNewCourse();
                setStartTime();
                setEndTime();
            }
        }).catch(error => 
            console.error('Error:', error));
      }

    //강의 삭제
    const deleteCourse = () =>{
        const deleteID = courseData[selectedRow2].course_id
        const result = window.confirm("선택된 강의를 삭제하시겠습니까?");
            if (result) {
                axios({
                    url : `${back_ip_url}/deleteCourse`,
                    data : {
                        deleteID : deleteID,
                        userId : userInfo.id
                    },
                    withCredentials: true,
                    method: "POST"
                }).then(response=>{
                    if (response.status === 200){
                        alert("삭제 완료")
                        fetchStudentData();
                        fetchCourseData();
                        setSelectedRow2(0)
                        setSelectedRow3(0)
                    }
                }).catch(error => 
                    console.error('Error:', error));
            } else {
                // 사용자가 취소를 선택한 경우
                console.log("사용자가 취소를 선택했습니다.");
            }
        
    }

    //강의 수정
    const [editCourseName,setEditCourse] = useState()
    const [startTime2,setStartTime2] = useState()
    const [endtTime2,setEndTime2] = useState()
    var checkedDays2 = [];

    const editCourse = () =>{
        const ID = courseData[selectedRow2].course_id

        if (document.getElementById("mon2").checked) checkedDays2.push("월");
        if (document.getElementById("tue2").checked) checkedDays2.push("화");
        if (document.getElementById("wed2").checked) checkedDays2.push("수");
        if (document.getElementById("thu2").checked) checkedDays2.push("목");
        if (document.getElementById("fri2").checked) checkedDays2.push("금");
        if (document.getElementById("sat2").checked) checkedDays2.push("토");
        if (document.getElementById("sun2").checked) checkedDays2.push("일");
        axios({
            url: `${back_ip_url}/editCourse`,
            data : {
                id : ID,
                name : editCourseName,
                start : startTime2,
                end : endtTime2,
                day : checkedDays2,
                userId : userInfo.id
            },
            method: "POST",
            withCredentials: true,
        }).then(response=>{
            if (response.status === 200){
                alert("수정 완료")
                setModalIsOpen5(false)
                fetchCourseData();
                fetchStudentData();
                fetchStudentCourseData();
            }
        }).catch(error => 
            console.error('Error:', error));
    }

    //강의 학생 추가
    const [tuition,setTuition] = useState()

    const addStudentCourse = () =>{

        axios({
            url: `${back_ip_url}/addStudentCourse`,
            data : {
                student : studentData[selectedRow].student_id,
                course : courseData[selectedRow3].course_id,
                tuition : tuition,
                userId : userInfo.id
            },
            method: "POST",
            withCredentials: true,
        }).then(response=>{
            if (response.status === 200){
                alert("신규 강의 등록 완료")
                setModalIsOpen7(false)
                fetchCourseData();
                fetchStudentData();
                fetchStudentCourseData();
                setTuition();
                setSelectedRow3();
            }
        }).catch(error => {
            if (error.status === 400){
                console.error('Error:', error)
                alert("신규 강의 등록 실패")
            }
        }) 
    }   
    //학생 강의 취소
    const cancelCourse = () =>{
        const result = window.confirm("강의를 취소하시겠습니까?");
        if(result){
        axios({
            url: `${back_ip_url}/cancelCourse`,
            data : {
                student : studentData[selectedRow].student_id,
                course : studentCourseData[selectedRow5].Course_id,
                userId : userInfo.id
            },
            method: "POST",
            withCredentials: true,
        }).then(response=>{
            if (response.status === 200){
                alert("신규 취소 완료")
                fetchCourseData();
                fetchStudentData();
                fetchStudentCourseData();
                setSelectedRow3();
            }
        }).catch(error => {
            if (error.status === 400){
                console.error('Error:', error)
                alert("신규 강의 등록 실패")
            }
        }) 
        }
    }
    return(
        <div>
            <Header></Header>
            <div className="classmanager">
                <ul className="tab_area">
                    <li className={activeTab === 'student' ? 'active' : ''}>
                        <span onClick={switchBtn}>학생 관리</span>
                    </li>
                    <li className={activeTab === 'course' ? 'active' : ''}>
                        <span onClick={switchBtn2}>수강반 관리</span>
                    </li>
                </ul>
                <div className="student block" id="student">
                    <div className="button_bar">
                        <button className="buttonB btnColorA" style={{ width: '17%' }} onClick={openAddStudent}><FaPersonCirclePlus className="iconB"/> 신규 학생 등록</button>
                        <button className="buttonB btnColorA" style={{ width: '17%' }} onClick={openEditStudent}  disabled={selectedRow==null}><FaPersonCircleExclamation className="iconB"/> 학생 정보 수정</button>
                        <button className="buttonB btnColorA" style={{ width: '17%' }} onClick={deleteStudent} disabled={selectedRow==null}><FaPersonCircleMinus className="iconB"/> 학생 정보 삭제</button>
                        <button className="buttonB btnColorA" style={{ width: '17%' }} onClick={openStudentCourse} disabled={selectedRow==null}><FaPersonCircleQuestion className="iconB"/> 수강반 확인</button>
                        <button className="buttonB btnColorA" style={{ width: '17%' }} onClick={openNewStudentCourse} disabled={selectedRow==null}><FaPencilAlt className="iconB" style={{ fontSize: '18px' }}/> 수강반 등록</button>
                        {/* <button onClick={logcheck}>logcheck</button> */}
                    </div>
                    <div>
                        <Modal className="Modal_classmanager2" isOpen={modalIsOpen} ariaHideApp={false} onRequestClose={() => setModalIsOpen(false)}>
                            <div className="Modal_head">
                                <h2>신규 학생 등록</h2>
                                <button className="xMark" onClick={() => setModalIsOpen(false)}><IoMdClose /></button>
                            </div>
                            <table id="dataTable2" className="classmange_table">
                                <tr>
                                    <td>이름</td>
                                    <td><input type="text" placeholder="학생이름을 입력해주세요" className="inputA" onChange={(e) => setNewName(e.target.value)}/></td>
                                </tr>
                                <tr>
                                    <td>학년</td>
                                    <td>
                                        <select className='selectTree' style={{width : '200px',height:'34px'}} value={newSchoolGrade} onChange={(e) => setNewSchoolGrade(e.target.value)}>
                                        <option value='0'>전체 보기</option>
                                        {school_grade.map((school) => (
                                        <option key={school.id} value={school.id}>
                                            {school.name}
                                        </option>
                                        ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>학생<br/>연락처</td>
                                    <td><input type="text" onInput={(e) => oninputPhone(e.target)} maxlength="13" placeholder="010-0000-0000" className="inputA" onChange={(e) => setNewPhone(e.target.value)}/></td>
                                </tr>
                                <tr>
                                    <td>학부모 연락처</td>
                                    <td><input type="text" onInput={(e) => oninputPhone(e.target)} maxlength="13" placeholder="010-0000-0000" className="inputA" onChange={(e) => setNewPPhone(e.target.value)}/></td>
                                </tr>
                            </table>
                            <button className="buttonB btnColorA confirm_btn" onClick={addStudent}>학생 정보 저장</button> 
                        </Modal>
                        <Modal className="Modal_classmanager2" isOpen={modalIsOpen2} onRequestClose={() => setModalIsOpen2(false)}>
                            <div className="Modal_head">
                                <h2>학생 정보 수정</h2>
                                <button className="xMark" onClick={() => setModalIsOpen2(false)}><IoMdClose /></button>
                            </div>
                            {selectedRow>-1 ? 
                                <table id="dataTable2" className="classmange_table">
                                <tr className="student_info">
                                    <td>이름</td>
                                    <td>
                                        <input
                                            type="text"
                                            className="inputA"
                                            defaultValue={studentData[selectedRow].student_name}
                                            placeholder={studentData[selectedRow].student_name}
                                            onChange={(e) => setEditName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>학년</td>
                                    <td>
                                        <select className='selectTree' style={{width : '200px',height:'34px'}} value={editSchoolGrade} onChange={(e) => setEditSchoolGrade(e.target.value)}>
                                        <option value='0'>전체 보기</option>
                                        {school_grade.map((school) => (
                                        <option key={school.id} value={school.id}>
                                            {school.name}
                                        </option>
                                        ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>학생<br/>연락처</td>
                                    <td>
                                        <input
                                            type="text"
                                            className="inputA"
                                            defaultValue={studentData[selectedRow].phoneNumber}
                                            placeholder={studentData[selectedRow].phoneNumber}
                                            onInput={(e) => oninputPhone(e.target)}
                                            onChange={(e) => setEditPhone(e.target.value)}
                                            maxlength="13"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>학부모 연락처</td>
                                    <td>
                                        <input
                                            type="text"
                                            className="inputA"
                                            defaultValue={studentData[selectedRow].parent_phone}
                                            placeholder={studentData[selectedRow].parent_phone}
                                            onInput={(e) => oninputPhone(e.target)}
                                            onChange={(e) => setEditPPhone(e.target.value)}
                                            maxlength="13"
                                        />
                                    </td>
                                </tr>
                            </table>

                                : ('선택되지 않음')}
                            <button className="buttonB btnColorA confirm_btn"onClick={editStudent}>학생 정보 저장</button>
                        </Modal>
                        <Modal className="Modal_classmanager" isOpen={modalIsOpen3} onRequestClose={() => setModalIsOpen3(false)}>
                            <div className="Modal_head">
                                <h2>수강반 확인</h2>
                                <button className="xMark" onClick={() => setModalIsOpen3(false)}><IoMdClose /></button>
                            </div>
                            <button className="buttonA btnColorA" onClick={cancelCourse}>강의 취소</button>
                            <button onClick={logcheck}>logcheck</button>
                            {selectedRow > -1 ? (
                                <div>
                                    <table id="dataTable2" className="classmange_table">
                                        <thead>
                                            <tr>
                                                <th>반 이름</th>
                                                <th>강의 시간</th>
                                                <th>강의 요일</th>
                                                <th>수강료</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {studentCourseData.map((studentCourse, index) => {
                                                if (studentCourse.Student_id === studentData[selectedRow].student_id) {
                                                    const course = courseData.find(course => course.course_id === studentCourse.Course_id);
                                                    if (course) {
                                                        return (
                                                            <tr key={index} style={{ backgroundColor: selectedRow5 === index 
                                                                ? 'lightblue' : 'white' }} onClick={() => handleRowClick5(index)}>
                                                                <td>{course.course_name}</td>
                                                                <td>{course.course_start} ~ {course.course_end}</td>
                                                                <td>{course.course_week}</td>
                                                                <td>{studentCourse.tuition}</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                                return null
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : ('선택되지 않음')}
                        </Modal>
                        <Modal className="Modal_classmanager" isOpen={modalIsOpen7} onRequestClose={() => setModalIsOpen7(false)}>
                            <div className="Modal_head">
                                <h2>수강반 등록</h2>
                                <button className="xMark" onClick={() => setModalIsOpen7(false)}><IoMdClose /></button>
                            </div>
                            <div className="class2">
                                <input className="inputA btnColorA"placeholder="수강료 입력" type="number" onChange={(e)=> setTuition(e.target.value)}/>
                                <button className="buttonB btnColorA"disabled={selectedRow3==null} onClick={addStudentCourse}>신규 강의 등록</button>
                            </div>
                            {selectedRow>-1 ? 
                                    <div>
                                        <table id="dataTable2">
                                            <tr className="student_info">
                                                <th>ID</th>
                                                <th>반 이름</th>
                                                <th>강의 시간</th>
                                                <th>강의 요일</th>
                                            </tr>
                                            <tbody>
                                                {courseData ? (courseData.map((course, index) => (
                                                        <tr key={course.course_id} style={{ backgroundColor: selectedRow3 === index 
                                                        ? 'lightblue' : 'white' }} onClick={() => handleRowClick3(index)}>
                                                            <td>{course.course_id}</td>
                                                            <td>{course.course_name}</td>
                                                            <td>{course.course_start+' ~ '+course.course_end}</td>
                                                            <td>{course.course_week}</td>
                                                        </tr>
                                                    ))) : ('강의 정보 불러오는 중...') }
                                            </tbody>
                                        </table>
                                    </div>
                                : ('선택되지 않음')}
                        </Modal>
                    </div>
                    <table id="dataTable">
                        <tr className="course_info">
                            <th>ID</th>
                            <th>이름</th>
                            <th>학년</th>
                            <th>학생 연락처</th>
                            <th>학부모 연락처</th>
                            <th>학원 등록일</th>
                        </tr>
                        <tbody>
                            {studentData ? (studentData.map((student, index) => (
                                    <tr key={student.student_id} style={{ backgroundColor: selectedRow === index 
                                    ? 'lightblue' : 'white' }} onClick={() => handleRowClick(index)}>
                                        <td>{student.student_id}</td>
                                        <td>{student.student_name}</td>
                                        <td>{getGradeName(student.grade)}</td>
                                        <td>{student.phoneNumber}</td>
                                        <td>{student.parent_phone}</td>
                                        <td>{formatDate(student.date)}</td>
                                    </tr>
                                ))) : ('학생 정보 불러오는 중...') }
                                
                        </tbody>
                    </table>
                </div>
                <div className="course none" id="course">
                    <div className="button_bar">
                        <button className="buttonB btnColorA" style={{ width: '23%' }} onClick={openAddCourse}><CgAddR className="iconB"/> 신규 강의 등록</button>
                        <button className="buttonB btnColorA" style={{ width: '23%' }} onClick={openEditCourse} disabled={selectedRow2==null}><FiEdit className="iconB"/> 강의 수정</button>
                        <button className="buttonB btnColorA" style={{ width: '23%' }} onClick={deleteCourse} disabled={selectedRow2==null}><CgRemoveR className="iconB"/> 강의 삭제</button>
                        <button className="buttonB btnColorA" style={{ width: '23%' }} onClick={openCheckStudent} disabled={selectedRow2==null}><FaPersonCircleQuestion className="iconB"/> 수강생 확인</button>
                    </div>
                    <div>
                        <Modal className="Modal_classmanager2" isOpen={modalIsOpen4} onRequestClose={() => setModalIsOpen4(false)}>
                            <div className="Modal_head">
                            <h2>신규 강의 등록</h2>
                                <button className="xMark" onClick={() => setModalIsOpen4(false)}><IoMdClose /></button>
                            </div>

                            <h3>이름</h3><input onChange={(e)=>setNewCourse(e.target.value)}></input>
                            <div>
                                <h3>강의 요일</h3>
                                <input type="checkbox" id="mon" value="월"/>월
                                <input type="checkbox" id="tue" value="화"/>화
                                <input type="checkbox" id="wed" value="수"/>수
                                <input type="checkbox" id="thu" value="목"/>목
                                <input type="checkbox" id="fri" value="금"/>금
                                <input type="checkbox" id="sat" value="토"/>토
                                <input type="checkbox" id="sun" value="일"/>일
                            </div>
                            <h3>강의 시간</h3>
                            <div className="time_select">
                                <input type="time" onChange={(e)=>setStartTime(e.target.value)}/>
                                <h2> ~ </h2>
                                <input type="time" onChange={(e)=>setEndTime(e.target.value)}/>
                            </div>
                            <button className="buttonB btnColorA confirm_btn" onClick={saveCheckedDays}>저장</button>
                        </Modal>
                        <Modal className="Modal_classmanager2" isOpen={modalIsOpen5} onRequestClose={() => setModalIsOpen5(false)}>
                            <div className="Modal_head">
                                <h2>강의 수정</h2>
                                <button className="xMark" onClick={() => setModalIsOpen5(false)}><IoMdClose /></button>
                            </div>
                            {selectedRow2 == null || courseData == null ? ("선택안됨") :(<div>
                                <h3>이름</h3>
                                <input 
                                defaultValue={courseData[selectedRow2].course_name}
                                placeholder={courseData[selectedRow2].course_name}
                                onChange={(e)=>setEditCourse(e.target.value)} ></input>
                                <div>
                                    <h3>강의 요일</h3>
                                    <input type="checkbox" id="mon2" value="월"/>월
                                    <input type="checkbox" id="tue2" value="화"/>화
                                    <input type="checkbox" id="wed2" value="수"/>수
                                    <input type="checkbox" id="thu2" value="목"/>목
                                    <input type="checkbox" id="fri2" value="금"/>금
                                    <input type="checkbox" id="sat2" value="토"/>토
                                    <input type="checkbox" id="sun2" value="일"/>일
                                </div>
                                <h3>강의 시간</h3>
                                <div className="time_select">
                                    <input defaultValue={courseData[selectedRow2].course_start} type="time" onChange={(e)=>setStartTime2(e.target.value)}/>
                                    <h2> ~ </h2>
                                    <input defaultValue={courseData[selectedRow2].course_end} type="time" onChange={(e)=>setEndTime2(e.target.value)}/>
                                </div>
                                <button className="buttonB btnColorA confirm_btn" onClick={editCourse}>저장</button>
                            </div>)}

                        </Modal>
                        <Modal className="Modal_classmanager" isOpen={modalIsOpen6} onRequestClose={() => setModalIsOpen6(false)}>
                            <div className="Modal_head">
                                <h2>수강생 확인</h2>
                                <button className="xMark" onClick={() => setModalIsOpen6(false)}><IoMdClose /></button>
                            </div>

                            {selectedRow2 > -1 ? (
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>학생 ID</th>
                                                <th>학생 이름</th>
                                                <th>학교/학년</th>
                                                <th>학생 연락처</th>
                                                <th>학부모 연락처</th>
                                                <th>수강료</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {studentCourseData.map((studentCourse, index) => {
                                                if (studentCourse.Course_id === courseData[selectedRow2].course_id) {
                                                    const student = studentData.find(student => student.student_id === studentCourse.Student_id);
                                                    if (student) {
                                                        return (
                                                            <tr key={index} style={{ backgroundColor: selectedRow4 === index 
                                                                ? 'lightblue' : 'white' }} onClick={() => handleRowClick4(index)}>
                                                                <td>{student.student_id}</td>
                                                                <td>{student.student_name}</td>
                                                                <td>{getGradeName(student.grade)}</td>
                                                                <td>{student.phoneNumber}</td>
                                                                <td>{student.parent_phone}</td>
                                                                <td>{studentCourse.tuition}</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : ('선택되지 않음')}
                        </Modal>
                        <table id="dataTable">
                            <tr className="student_info">
                                <th>ID</th>
                                <th>반 이름</th>
                                <th>강의 시간</th>
                                <th>강의 요일</th>
                            </tr>
                            <tbody>
                                {courseData ? (courseData.map((course, index) => (
                                        <tr key={course.student_id} style={{ backgroundColor: selectedRow2 === index 
                                        ? 'lightblue' : 'white' }} onClick={() => handleRowClick2(index)}>
                                            <td>{course.course_id}</td>
                                            <td>{course.course_name}</td>
                                            <td>{course.course_start+' ~ '+course.course_end}</td>
                                            <td>{course.course_week}</td>
                                        </tr>
                                    ))) : ('강의 정보 불러오는 중...') }
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    )
}